import L from 'leaflet';
import 'leaflet.awesome-markers'
import 'leaflet.icon.glyph'
import 'leaflet-draw'
import store from './store'

import {
    eventBus
} from './main'

let map = {};
var singlePointLayer = L.layerGroup();
var voyagePointsLayer = L.layerGroup();
var points = L.layerGroup();
var zones = L.layerGroup();
var drawnItems = new L.FeatureGroup();

var selectedDrawnPolygon = null;

var routeLayer = L.geoJson([], {
    style: function (feature) {
        return {
            color: "blue",
            weight: 10,
            opacity: 0.65
        };
    },
    onEachFeature: function (feature, layer) {
        layer.bindPopup("Route");
    }
})
var vpEditorPoints = L.layerGroup();
var orderPoints = L.layerGroup();

export default {
    init(el) {
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });

        var initialLocation = [59.942286, 30.305557]
        if (store.getters["accountSettings/latLon"]) {
            initialLocation = store.getters["accountSettings/latLon"]
        }

        map = L.map(el).setView(initialLocation, 11)

        L.tileLayer('http://api.probki.net/map/{z}/{x}/{y}', {
            maxZoom: 20,
            maxNativeZoom: 18,
            attribution: "Map data &copy; <a href='http://probki.net/b2b'>MIT GEOMATICS</a>"
        }).addTo(map);

        points.addTo(map);
        routeLayer.addTo(map);
        singlePointLayer.addTo(map);
        voyagePointsLayer.addTo(map);
        vpEditorPoints.addTo(map);
        orderPoints.addTo(map);
        zones.addTo(map);

        map.on('contextmenu', function (e) {
            eventBus.$emit('addRouteItem', {
                lat: e.latlng.lat,
                lng: e.latlng.lng
            })
        });

        map.addLayer(drawnItems);
        var drawControl = new L.Control.Draw({
            draw: {
                polygon: true,
                circle: false,
                marker: false,
                polyline: false,
                rectangle: false,
                circlemarker: false
            },
            edit: {
                featureGroup: drawnItems
            }
        });

        map.addControl(drawControl);

        map.on("draw:created", function (e) {
            var type = e.layerType,
                layer = e.layer;
            layer.addTo(drawnItems);

            eventBus.$emit("zoneCreated", e.layer.toGeoJSON())
        });

        map.on("draw:edited",
            function (e) {
                eventBus.$emit("zoneUpdated", e.layers.toGeoJSON())
            })
    },
    generateRandInt() {
        return Math.floor(Math.random() * 200000) + 1;
    },
    addMarker(lat, lon, id) {
        var marker = L.marker([lat, lon], {
            draggable: true
        })
        marker.ext_id = id
        marker.on('dragend', function (e) {
            eventBus.$emit('changeItemLatLng', {
                id: marker.ext_id,
                lat: e.target._latlng.lat,
                lng: e.target._latlng.lng
            })
        });
        marker.addTo(points);
        return marker;
    },
    removeMarker(marker) {
        if (marker) {
            points.removeLayer(marker);
        }
    },
    drawRoute(data) {
        routeLayer.clearLayers();
        routeLayer.addData(data);
    },
    removeRoute() {
        routeLayer.clearLayers();
    },
    singleMarker(lat, lon, zoomLevel = 17) {
        singlePointLayer.clearLayers();
        var marker = L.marker([lat, lon]);
        marker.addTo(singlePointLayer);
        map.setView([lat, lon], zoomLevel);
    },
    singleMarkerForSetRoutePoint(lat, lon) {
        singlePointLayer.clearLayers();
        var marker = L.marker([lat, lon], {
            draggable: true
        });

        marker.on('dragend', function (e) {
            eventBus.$emit('changeVPCmdLatLng', {
                lat: e.target._latlng.lat,
                lng: e.target._latlng.lng
            })
        });

        marker.addTo(singlePointLayer);
        map.setView([lat, lon], 17);
    },
    deleteSingleMarker() {
        singlePointLayer.clearLayers();
    },
    setView(latLng, zoomLevel = 17) {
        map.setView([latLng.lat, latLng.lon], zoomLevel);
    },
    panTo(lat, lon) {
        map.panTo([lat, lon]);
    },
    drawVPoints(points) {
        voyagePointsLayer.clearLayers();

        for (var i = 0; i < points.length; i++) {
            var point = points[i];
            var marker = L.marker([point.lat, point.lon], {
                icon: L.icon.glyph({
                    prefix: '',
                    cssClass: 'sans-serif',
                    glyph: i + 1
                })
            })
            if (point.ttt) {
                marker.bindPopup(point.ttt, {
                    autoClose: false
                })
            }
            marker.on('click', this.vPointClick)
            marker.addTo(voyagePointsLayer);
            if (i === 0) {
                map.flyTo([point.lat, point.lon], 15);
            }
        }
        // voyagePointsLayer.eachLayer(l => {
        //     l.openPopup()
        // })
    },
    vPointClick(e) {
        eventBus.$emit('selectVPcmd', {
            latlng: e.latlng
        })
    },
    vpEditorAddPoint(latlng, id, title = null) {
        var marker = L.marker([latlng.lat, latlng.lng], {
            vpid: id
        });
        if (title) {
            marker.bindTooltip(title, {
                permanent: true
            })
        }
        marker.on('click', () => {
            eventBus.$emit('selectVPcmd', {
                id
            })
        })
        marker.addTo(vpEditorPoints);
    },
    vpEditorDeletePoint(id) {
        vpEditorPoints.eachLayer(l => {
            if (l.options.vpid === id) {
                vpEditorPoints.removeLayer(l);
            }
        })
    },
    removeToolTips() {
        vpEditorPoints.eachLayer(feature => feature.unbindTooltip())
    },
    clearAll() {
        singlePointLayer.clearLayers()
        voyagePointsLayer.clearLayers()
        points.clearLayers()
        routeLayer.clearLayers()
        vpEditorPoints.clearLayers()
        orderPoints.clearLayers()
    },
    drawZone(data) {
        console.log(data)
        var zone = L.geoJson(data
            //     , {
            //     onEachFeature: function (feature, layer) {
            //         layer.on('click', function (e) {
            //             if (selectedDrawnPolygon) {
            //                 selectedDrawnPolygon.editing.disable();
            //             }
            //             selectedDrawnPolygon = e.target;
            //             e.target.editing.enable();
            //         });
            //     }
            // }
        );
        zone.addTo(zones);
    },
    panToZone(geoJsonPolygon) {
        var layer = L.geoJson(geoJsonPolygon)
        var bounds = layer.getBounds();
        map.fitBounds(bounds);
    },
    makeZoneEditable(zone) {
        drawnItems.clearLayers();

        L.geoJson(zone, {
            onEachFeature: function (feature, layer) {
                drawnItems.addLayer(layer);
            }
        })
    },
    clearZonesLayer() {
        drawnItems.clearLayers();
        zones.clearLayers();
    }
}