<template>
  <div>
    <h3>Reseller Cabinet</h3>
    
  </div>
</template>

<script>
export default {
  name: "ResellerCabinet",
  created() {
    //this.$store.dispatch("reseller/getReseller");
  },
  computed: {
    // status() {
    //   return this.$store.state.reseller.status;
    // },
    // distributor() {
    //   return this.$store.state.reseller.reseller;
    // }
  }
};
</script>