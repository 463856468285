<template>
  <div>
    <div v-if="amICollapsed">
      <button
        class="btn btn-outline-primary btn-sm"
        @click="amICollapsed = false"
      >
        Add new map
      </button>
    </div>
    <form v-else class="form" @submit.prevent="addNewMap">
      <table class="table table-sm">
        <tr>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.name"
              placeholder="map name"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.uniqueId"
              placeholder="unique id"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.substituteMapUniqueId"
              placeholder="subst.unique id"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.edition"
              placeholder="ver"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.update"
              placeholder="subver"
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              v-model="curMap.magicKey"
              placeholder="magic key"
            />
          </td>
          <td>
            <button
              type="submit"
              class="btn btn-primary btn-sm"
              v-bind:class="{ disabled: mapsCollectionId === 0 }"
            >
              Add
            </button></td><td>
            <button
              class="btn btn-warning btn-sm"
              @click="
                amICollapsed = true;
                curMap = {};
              "
            >
              Cancel
            </button>
          </td>
        </tr>
      </table>
    </form>
    <hr>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    map: {
      type: Object,
      default: () => {
        return { name: "" };
      },
    },
    mapsCollectionId: { type: Number, default: 0 },
  },
  data() {
    return {
      amICollapsed: true,
      curMap: this.map,
    };
  },
  methods: {
    addNewMap() {
      var map = this.curMap;
      map.mapsCollectionId = this.mapsCollectionId;
      map = JSON.stringify(map);
      myAxios
        .post("/maps/AddMap", map, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.curMap = {};
          this.amICollapsed = true;
          this.$store.dispatch("mapsCollections/getMapsCollection");
        });
    },
  },
};
</script>