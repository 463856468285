<template>
  <div>
    <div v-for="car in cars" :key="car.deviceId" class="brd">
      <!-- <div>
        <div v-if="car.details && car.details.driverName">
          {{ car.details.driverName }}
          <div class="bh">
            {{ car.details.carClass }} {{ car.details.carModel }} ({{ car.details.carColor }}) [{{
              car.details.carNumber
            }}] <br />child seats: {{ car.details.childSeatsCount }}
          </div>
        </div>
        <div v-else class="brdbtm">
          <span v-if="car.nickname">{{ car.nickname }}</span>
          <span v-else>{{ car.deviceId }}</span>
        </div>
      </div>
      <car-info :appState="car.appState"></car-info> -->
      <driver-cart :car="car"></driver-cart>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import CarInfo from "./CarInfo.vue";
import DriverCart from "./DriverCart.vue"

export default {
  components: { DriverCart },
  data() {
    return {
      cars: [],
    };
  },
  mounted() {
    this.loadCars();
  },
  methods: {
    loadCars() {
      myAxios.get("/api/taxiorders/cars/").then((resp) => {
        this.cars = resp.data;
      });
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}

.bh{
  font-size: 14px;
  border-top: solid 1px #ced4da;
  border-bottom: solid 1px #ced4da;
}

.brdbtm{
  border-bottom: solid 1px #ced4da;
}
</style>