<template>
  <div>
    <button
      class="btn btn-outline-primary btn-sm m-2"
      v-if="isHidden"
      @click="isHidden = false"
    >
      Add command
    </button>
    <div
      v-else
      style="
        border: solid 1px #ced4da;
        border-radius: 5px;
        padding: 15px;
        margin: 5px;
      "
    >
      <h6>Command</h6>
      <div class="form-group">
        <label>Type</label
        ><select v-model="cmd.type" class="form-control">
          <option value="" disabled selected>select type</option>
          <option value="goTo">goTo</option>
          <option value="execute">execute</option>
        </select>
      </div>
      <!-- <div class="form-group">
        <label>State:</label
        ><input type="text" v-model="cmd.state" class="form-control" />
      </div> -->
      <div v-if="cmd.type">
        <div class="form-group">
          <label>Action Title</label
          ><input type="text" v-model="cmd.action" class="form-control" />
        </div>
        <div class="form-group">
          <label>Comment</label
          ><input type="text" v-model="cmd.comment" class="form-control" />
        </div>
        <address-bar @setLocation="setLocation" v-if="cmd.type === 'goTo'" />
        <div class="form-group">
          <label>Planned Time</label>
          <div>
            <date-picker
              v-model="cmd.plannedTimeStart"
              type="datetime"
              placeholder="Start"
              value-type="format"
              format="YYYY-MM-DD HH:mm"
              style="width: 185px"
            ></date-picker>
            <span>
              -
              <date-picker
                v-model="cmd.plannedTimeEnd"
                type="datetime"
                placeholder="End"
                value-type="format"
                format="YYYY-MM-DDTHH:mm"
              ></date-picker>
            </span>
          </div>
          <div class="form-group mt-3">
            <label v-if="cmd.type === 'execute'">Duration</label>
            <label v-if="cmd.type === 'goTo'">TimeSpan</label>
            <input
              type="number"
              v-model="cmd.plannedTimeDuration"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div>
        <button
          class="btn btn-outline-primary btn-sm"
          :disabled="!isComplete"
          @click="save"
        >
          Save Command
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AddressBar from "./AddressBar.vue";
import moment from "moment";

export default {
  components: { DatePicker, AddressBar },
  data() {
    return {
      cmd: {
        type: "",
        state: "planned",
        action: "",
        comment: "",
        address: "",
        lat: 0,
        lon: 0,
        plannedTimeDuration: 0,
        plannedTimeStart: "",
        plannedTimeEnd: "",
      },
      isHidden: true,
    };
  },
  computed: {
    isComplete() {
      if (this.cmd.type === "goTo") return this.cmd.lat && this.cmd.lon;
      else if (this.cmd.type === "execute") {
        return this.cmd.plannedTimeStart || this.cmd.plannedTimeDuration;
      } else return false;
    },
  },
  // watch: {
  //   "cmd.plannedTimeDuration": function () {
  //     if (this.cmd.plannedTimeDuration > 0) {
  //       this.cmd.plannedTimeEnd = moment(this.cmd.plannedTimeStart)
  //         .add(this.cmd.plannedTimeDuration, "m")
  //         .format("YYYY-MM-DD HH:mm");
  //     }
  //   },
  // },
  methods: {
    setLocation(loc) {
      this.cmd.address = loc.address;
      this.cmd.lat = Number(loc.latLon.lat).toFixed(6);
      this.cmd.lon = Number(loc.latLon.lon).toFixed(6);
    },
    save() {
      var cmdCopy = { ...this.cmd };

      if (this.cmd.plannedTimeStart) {
        cmdCopy.plannedTimeStart = moment(this.cmd.plannedTimeStart).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }

      if (this.cmd.plannedTimeEnd) {
      cmdCopy.plannedTimeEnd = moment(this.cmd.plannedTimeStart)
        .add(this.cmd.plannedTimeDuration, "m")
        .format("YYYY-MM-DDTHH:mm:ss");
      }

      cmdCopy.plannedTimeDuration = Number(this.cmd.plannedTimeDuration);

      cmdCopy.action = this.cmd.action
        ? this.cmd.action
        : // : this.cmd.address
          // ? this.cmd.address
          "no_name_action";

      this.$emit("addCommand", cmdCopy);
      this.cmd = {
        state: "planned",
        type: "",
        address: "",
        lat: 0,
        lon: 0,
        comment: "",
        plannedTimeDuration: 0,
        plannedTimeStart: "",
        plannedTimeEnd: "",
      };
      this.isHidden = true;
    },
  },
};
</script>

<style scoped>
</style>