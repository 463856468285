<template>
  <div>
    <div
      v-if="registration && registration.success"
      class="alert alert-success"
      role="alert"
    >{{ $t('registration.success') }}</div>
    <login v-if="loginMode==='login'" @changeMode="changeMode"></login>
    <registration v-else-if="loginMode==='register'" @changeMode="changeMode"></registration>
    <remember-password v-else-if="loginMode==='repassword'" @changeMode="changeMode"></remember-password>
    <hr />
  </div>
</template>

<script>
import Login from "@/components/signUpSignIn/Login";
import Registration from "@/components/signUpSignIn/Registration.vue";
import RememberPassword from "@/components/signUpSignIn/RememberPassword.vue";

export default {
  components: { Login, Registration, RememberPassword },
  data() {
    return { loginMode: "login" };
  },
  computed: {
    registration() {
      return this.$store.state.auth.registration;
    }
  },
  methods: {
    changeMode(mode) {
      this.loginMode = mode;
    }
  },
  watch: {
    registration: function() {
      if (this.registration.success) {
        this.loginMode = "login";
      }
    }
  }
};
</script>