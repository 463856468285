<template>
  <div class="brd" @click="showOnMap">
    <order-state :order="order"></order-state>
    <div class="mb-2">
      <span>{{ mmoment(order.pickupTime) }} - </span>
      <span v-for="(point, index) in order.routePoints" :key="index">
        <span v-if="point.address">{{ point.address }}</span>
        <span v-else>{{ point.lat }} {{ point.lon }}</span>
        <span v-if="index + 1 < order.routePoints.length"> - </span>
      </span>
      <hr />
      <span>CarClass: {{ order.carClass }}, Price: {{ order.price }} {{order.currency}}</span>
    </div>
    <div>
      <assigned-car
        v-if="order.assignedCar && order.state !== 'cancelled'"
        :assignedCar="order.assignedCar"
        v-on:cancel="order.assignedCar = null"
      ></assigned-car>
      <div v-else>
        <button
          v-if="!showCarVariants"
          class="btn btn-outline-primary btn-sm"
          @click="showCarVariants = true"
        >
          Choose car
        </button>
        <choose-car
          v-else
          :key="Date.now()"
          v-on:cancelAC="showCarVariants = false"
          :orderId="order.id"
        ></choose-car>
        <button class="btn btn-outline-danger btn-sm" @click="deleteOrder">
          Delete order
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import map from "@/map2.js";
import ChooseCar from "./ChooseCar.vue";
import AssignedCar from "./AssignedCar.vue";
import OrderState from "./OrderState.vue";
import myAxios from "@/services/myAxios";
import axios from "axios";

export default {
  components: { ChooseCar, AssignedCar, OrderState },
  props: {
    order: {},
  },
  data() {
    return { showCarVariants: false };
  },
  methods: {
    mmoment(dateStr) {
      return moment(dateStr).format("DD.MM.YY HH:mm");
    },
    cancel() {
      map.clearAll();
      this.$emit("orderChanged");
    },
    deleteOrder() {
      myAxios.delete("/api/taxiorders/" + this.order.id).then(() => {
        this.cancel();
      });
    },
    showOnMap() {
      map.drawVPoints(this.order.routePoints);
      this.drawRoute();
    },
    drawRoute() {
      map.removeRoute();

      var goTos = [];
      this.order.routePoints.forEach((point) => {
        goTos.push({
          latitude: Number(point.lat),
          longitude: Number(point.lon),
        });
      });

      if (goTos.length > 1) {
        var routeCalcParams = {
          type: "OptimizeTime",
          allowYards: true,
          allowSideWays: false,
          allowGroundRoads: true,
          allowPaidRoads: true,
          allowRailwayCrossing: true,
          useJams: false,
          useLimitsUpd: true,
          useForecast: false,
        };

        var routes = [];
        routes.push({
          Points: goTos,
        });

        var request = {
          accessKey: "WWWB2BPROBKINET",
          getPoints: true,
          parameters: routeCalcParams,
          routes: routes,
          carParameters: null,
        };

        axios
          .post("https://api.probki.net/api/route", request, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            map.drawRoute(resp.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}
</style>