<template>
  <div>
    <div>
      <button
        class="btn btn-sm btn-outline-primary mr-2"
        @click="getAppState()"
      >
        Refresh
      </button>
      <button class="btn btn-sm btn-outline-primary" @click="getAppStateFile()">
        Get AppState File
      </button>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-4">
        <div v-if="appState" class="appstate">
          <div v-if="appState">
            <h5>Current state</h5>
            <dl>
              <dt>Timestamp</dt>
              <dd>
                GMT: {{ fromOADate(appState.timestamp)
                }}<span v-if="appState.timestampLocal"
                  ><br />Local: {{ fromOADate(appState.timestampLocal) }}</span
                >
              </dd>
              <dt>Location</dt>
              <dd
                v-if="appState.lat"
                class="clickable"
                @click="showOnMap(appState.lat, appState.lon)"
              >
                {{ appState.lat.toFixed(6) }} {{ appState.lon.toFixed(6) }}
                <span v-if="appState.isSumulator"> [Simulator]</span
                ><span v-else> [GPS]</span>
                <div v-if="appState.address">{{ appState.address }}</div>
              </dd>
              <dt>Speed</dt>
              <dd>{{ appState.speed }}</dd>
              <dt>Course</dt>
              <dd v-if="appState.course">{{ appState.course.toFixed(2) }}</dd>
            </dl>
          </div>
          <div v-if="activeVP">
            <h5>Active Voyage Plan</h5>
            <dl>
              <dt>Title</dt>
              <dd>{{ activeVP.name ? activeVP.name : activeVP.filename }}</dd>
              <dt>Status</dt>
              <dd>{{ activeVP.status }}</dd>
              <dt>Current Step</dt>
              <dd>
                {{ activeVP.activeItem.name }} /
                {{ getActiveCommand(activeVP.activeItem.commands).action }}
              </dd>
              <dt>Finish</dt>
              <dd>
                <strong>ETA </strong>
                <ul class="list-unstyled">
                  <li>
                    GMT: {{ mmoment(appState.voyageTasks[0].estimatedEndTime) }}
                  </li>
                  <li>
                    Local:
                    {{ mmoment(appState.voyageTasks[0].estimatedEndTimeLocal) }}
                  </li>
                </ul>
                <div>
                  <strong>Location</strong>
                  <div
                    v-if="appState.voyageTasks[0].endLat"
                    class="clickable"
                    @click="showOnMap(appState.voyageTasks[0].endLat, appState.voyageTasks[0].endLon)"
                  >
                    {{ appState.voyageTasks[0].endLat.toFixed(6) }}
                    {{ appState.voyageTasks[0].endLon.toFixed(6) }}
                    <span v-if="appState.isSumulator"> [Simulator]</span
                    ><span v-else> [GPS]</span>
                    <div v-if="appState.voyageTasks[0].endAddress">
                      {{ appState.voyageTasks[0].endAddress }}
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div
            v-if="appState.routeSettings && appState.routeSettings.vehicleSize"
          >
            <h5>Vehicle Parameters</h5>
            <dl>
              <dt>Width</dt>
              <dd>{{ appState.routeSettings.vehicleSize.widthMet }} m.</dd>
              <dt>Height</dt>
              <dd>{{ appState.routeSettings.vehicleSize.heightMet }} m.</dd>
              <dt>Lenght</dt>
              <dd>{{ appState.routeSettings.vehicleSize.lengthMet }} m.</dd>
              <dt>Weght</dt>
              <dd>{{ appState.routeSettings.vehicleSize.weightTn }} t.</dd>
              <dt>Axle Load</dt>
              <dd>{{ appState.routeSettings.vehicleSize.axleLoadTn }} t.</dd>
            </dl>
          </div>
          <div v-if="appState.routeSettings">
            <h5>Route Settings</h5>
            <dt>Type</dt>
            <dd v-if="appState.routeSettings.routeType >= 0">
              {{
                Object.keys(routeTypes).find(
                  (key) => routeTypes[key] === appState.routeSettings.routeType
                )
              }}
            </dd>
            <dt>Vehicle Type</dt>
            <dd v-if="appState.routeSettings.vehicleType">
              {{
                Object.keys(vehicleTypes).find(
                  (key) =>
                    vehicleTypes[key] === appState.routeSettings.vehicleType
                )
              }}
            </dd>
            <strong class="blue">Routing Parameters</strong>

            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.useYards"
              />
              <label class="custom-control-label">Use Yards</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.useSideWays"
              />
              <label class="custom-control-label">Use Sideways</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.usePaidRoads"
              />
              <label class="custom-control-label">Use Paid Roads</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.useGroundRoads"
              />
              <label class="custom-control-label">Use Ground Roads</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.useFerries"
              />
              <label class="custom-control-label">Use Ferries</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                disabled
                v-model="appState.routeSettings.useAlterCross"
              />
              <label class="custom-control-label">Use Alter Cross</label>
            </div>
          </div>
          <div
            class="mt-2"
            v-if="appState.voyageTasks && appState.voyageTasks.Lenght > 0"
          >
            <h5>Voyage Tasks</h5>
            <ul v-for="vt in appState.voyageTasks" :key="vt.filename">
              <li>{{ vt.filename }} {{ vt.status }}</li>
            </ul>
          </div>
          <div v-if="appState.routeInfo">
            <h5>Route Info</h5>
            <dl>
              <dt>Start</dt>
              <dd
                class="clickable"
                @click="
                  showOnMap(
                    appState.routeInfo.start.lat,
                    appState.routeInfo.start.lon
                  )
                "
              >
                {{ appState.routeInfo.start.lat.toFixed(6) }}
                {{ appState.routeInfo.start.lon.toFixed(6) }}
              </dd>
              <dt>Finish</dt>
              <dd
                class="clickable"
                @click="
                  showOnMap(
                    appState.routeInfo.finish.lat,
                    appState.routeInfo.finish.lon
                  )
                "
              >
                {{ appState.routeInfo.finish.lat.toFixed(6) }}
                {{ appState.routeInfo.finish.lon.toFixed(6) }}
              </dd>
              <dt>Distance</dt>
              <dd>
                {{ (appState.routeInfo.finish.distance / 1000).toFixed(1) }} km
              </dd>
              <dt>ETA</dt>
              <dd>{{ secsToTime(appState.routeInfo.finish.time) }}</dd>
              <div v-if="appState.routeInfo.finish.currentStreet">
                <dt>Current Street</dt>
                <dd>{{ appState.routeInfo.finish.currentStreet }}</dd>
              </div>
              <dt>Route Points</dt>
              <dd v-if="appState.routeInfo.points">
                {{ appState.routeInfo.points.Lenght }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="col-8 mapdiv">
        <mymap></mymap>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "@/views/provider/geocontent/catalog/route-plan/Map.vue";
import map from "@/map2.js";
import myAxios from "@/services/myAxios";
import moment from "moment";

export default {
  components: {
    mymap: Map,
  },
  props: {
    deviceid: null,
  },
  data() {
    return {
      appState: {},
      routeTypes: Object.freeze({
        "Optimize time": 0,
        "Optimize distance": 1,
        Offroad: 2,
      }),
      vehicleTypes: Object.freeze({
        Default: 0,
        Car: 1,
        Truck: 2,
        Motobike: 3,
        Bicycle: 4,
        Pedestrian: 5,
        "Long Truck": 6,
        Bus: 7,
      }),
    };
  },
  created() {
    this.getAppState();
  },
  computed: {
    activeVP() {
      if (this.appState.voyageTasks && this.appState.voyageTasks.length > 0) {
        var avp = this.appState.voyageTasks.find((o) => o.status === "active");
        if (avp) return avp;
      }
      return null;
    },
  },
  methods: {
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
    getAppState() {
      myAxios
        .get("/api/teleapi/CarInfoRequest", {
          params: { deviceId: this.deviceid },
        })
        .then((response) => {
          this.appState = response.data;
          if (this.appState.lat) {
            this.showOnMap(this.appState.lat, this.appState.lon);
          }
        })
        .catch(console.error);
    },
    getAppStateFile() {
      myAxios
        .get("/api/teleapi/CarInfoRequest", {
          params: { deviceId: this.deviceid },
        })
        .then((response) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(response.data))
          );
          element.setAttribute("download", "appstate.json");
          element.style.display = "none";
          element.click();
          URL.revokeObjectURL(element.href);
        })
        .catch(console.error);
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    mmoment(strDateTime) {
      return moment(strDateTime).toDate().toLocaleString("ru");
    },
    secsToTime(secs) {
      var date = new Date(null);
      date.setSeconds(secs); // specify value for SECONDS here
      return date.toISOString().substr(11, 8);
    },
    getActiveCommand(commands) {
      if (commands) {
        var ac = commands.find((o) => o.state === "started");
        return ac;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.appstate dt {
  color: rgb(71, 128, 194);
}

.appstate {
  font-size: 14px;
}

.appstate h5 {
  font-size: 16px;
}

.blue {
  color: rgb(71, 128, 194);
}

.clickable {
  cursor: pointer;
  color: blue;
}
</style>