<template>
  <div class="my-2">
    <form>
      <div class="form-row align-items-center p-3 formbox">
        <div class="form-group col-md-6 ml-3">
          <label class="mt-2">Description</label>
          <input class="form-control" type="text" v-model="descr" />
          <small class="form-text text-muted">optional</small>
        </div>
        <div class="col-md-5 ml-3">
          <div class="custom-file">
            <input
              type="file"
              multiple
              class="custom-file-input"
              id="customFile"
              @change="uploadFile"
            />
            <label class="custom-file-label" for="customFile">Load Raw Bathimetric Data File</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return { descr: null };
  },
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    gcid() {
      return this.$store.state.geoContent.geoContent.id;
    }
  },
  methods: {
    uploadFile(event) {
      var formData = new FormData();

      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      formData.append("file", files[0]);
      formData.append("gcid", this.gcid);
      if(this.descr) formData.append("descr", this.descr);
      myAxios
        .post("/api/geocontent/addraw", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {})
    }
  }
};
</script>