<template>
  <div>
    <div class="my-2">
      <button class="btn btn-sm btn-outline-primary mr-2" @click="poiEditorHandler">POI Editor</button>
      <button class="btn btn-sm btn-outline-primary" @click="publishPoi">Publish Poi</button>
    </div>
    <div class="mt-4">
      <table class="table table-sm" v-if="pois">
        <thead>
          <tr>
            <th>Release Date</th>
            <th>Items Count</th>
          </tr>
        </thead>
        <tr v-for="p in pois" :key="p.id">
          <td><my-date :date="p.timestamp"></my-date></td>
          <td>{{p.itemsCount}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      pois: null
    };
  },
  created() {
    this.getPoiList();
  },
  methods: {
    getPoiList() {
      myAxios
        .get("/api/geocontent/GetPoiList")
        .then(resp => {
          this.pois = resp.data;
        })
        .catch();
    },
    poiEditorHandler() {
      myAxios
        .get("/api/geocontent/GoToPoiEditor")
        .then(resp => {
          if (resp.data == true) {
            var win = window.open(
              "https://service.probki.net/webpoi/",
              "_blank"
            );
            win.focus();
          }
        })
        .catch();
    },
    publishPoi() {
      myAxios
        .get("/api/geocontent/ConvertPoi")
        .then(resp => {
          this.getPoiList();
        })
        .catch();
    },
  }
};
</script>