import User from '../views/user/index.vue'
import UserLicenses from '../views/user/Licenses.vue'
import ControlCenter from '../views/user/control-center'
//import Cart from '../views/eshop/Cart.vue'

import Provider from '../views/provider/Home.vue'
import GeoContent from '../views/provider/geocontent/index.vue'
import Licenses from '../views/provider/geocontent/Licenses.vue'
import MyMap from '../views/provider/geocontent/Map.vue'
import Catalog from '../views/provider/geocontent/catalog'
import Login from '../views/Login.vue'
import Home from '../views/Home'
import Registartion from '../views/registration'
import Distributor from '../views/distributor/Home.vue'
import Reseller from '../views/reseller/Home.vue'
//import AddEnc from '@/components/geocontent/AddEnc'
import Enc from '../views/provider/geocontent/catalog/enc'
import Maps from '../views/provider/geocontent/catalog/maps'
import Raw from '../views/provider/geocontent/catalog/raw'
import EncList from '../views/provider/geocontent/catalog/enc/EncList.vue'
import S102 from '../views/provider/geocontent/catalog/s102'
import S102List from '../views/provider/geocontent/catalog/s102/s102List.vue'
import Ntm from '../views/provider/geocontent/catalog/ntm'
import Poi from '../views/provider/geocontent/catalog/poi/PoiList.vue'
import Confirm from '../views/provider/geocontent/catalog/enc/Confirm.vue'
import NavMessages from '../views/provider/geocontent/catalog/NavMessages'
import ProviderRegistartion from '../views/provider/ProviderRegistration.vue'
import DistributorRegistartion from '../views/distributor/DistributorRegistration.vue'
import Products from '../views/distributor/Products.vue'
import Distributors from '../views/provider/geocontent/Distributors.vue'
import DistributorLicenses from '../views/distributor/Licenses.vue'
import PublisherProducts from '../views/provider/geocontent/Products.vue'
import RoutePlan from '../views/provider/geocontent/catalog/route-plan'
import VoyagePlan from '../views/user/voyage-planer'
import VoyagePlanEditor from '../views/user/voyage-plan-editor'
import TaxiOrders from '../views/user/taxi-orders'
import Drivers from '../views/user/drivers'
import Installations from '../views/user/installations'
import Settings from '../views/user/settings'
import ZoneEditor from '../views/user/zone-editor'
import ServiceZoneEditor from '../views/user/service-zone-editor'
import TaxiRates from '../views/user/settings/TaxiRates/index.vue'


export default [{
    path: 'voyageplan',
    name: 'voyageplan',
    component: VoyagePlan,
    meta: {
      role: 'user'
    },
    props: true
  },
  {
    path: 'voyage-plan-editor',
    name: 'voyageplaneditor',
    component: VoyagePlanEditor,
    meta: {
      role: 'user'
    },
    props: true
  },
  {
    path: 'taxi-orders',
    name: 'taxiorders',
    component: TaxiOrders,
    meta: {
      role: 'user'
    },
    props: true
  },
  {
    path: 'drivers',
    name: 'drivers',
    component: Drivers,
    meta: {
      role: 'user'
    },
    props: true
  },
  {
    path: 'user',
    name: 'user',
    components: {
      default: User
    },
    meta: {
      role: 'user'
    },
    redirect: {
      name: 'userlicenses'
    },
    props: {
      default: true
    },
    children: [{
        path: 'licenses',
        name: 'userlicenses',
        components: {
          userView: UserLicenses
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'teleapi',
        name: 'teleapi',
        components: {
          userView: ControlCenter
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'installations',
        name: 'installations',
        components: {
          userView: Installations
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'settings',
        name: 'settings',
        components: {
          userView: Settings
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'zone-editor',
        name: 'zoneeditor',
        components: {
          userView: ZoneEditor
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'service-zone-editor',
        name: 'servicezoneeditor',
        components: {
          userView: ServiceZoneEditor
        },
        meta: {
          role: 'user'
        },
        props: true
      },
      {
        path: 'taxi-rates',
        name: 'taxirates',
        components: {
          userView: TaxiRates
        },
        meta: {
          role: 'user'
        },
        props: true
      }
    ]
  },
  {
    name: 'providerRegistration',
    component: ProviderRegistartion,
    path: 'provider/registration',
    meta: {
      role: 'user'
    }
  },
  {
    name: 'distributorRegistration',
    component: DistributorRegistartion,
    path: 'distributor/registration',
    meta: {
      role: 'user'
    }
  },
  {
    path: 'map',
    name: 'map',
    component: MyMap,
    meta: {
      role: 'provider'
    },
    props: true
  },
  {
    path: 'provider',
    name: 'provider',
    component: Provider,
    meta: {
      role: 'provider'
    },
    redirect: {
      name: 'maps'
    },
    children: [{
      name: 'geocontent',
      meta: {
        role: 'provider'
      },
      path: 'geocontent',
      components: {
        providerView: GeoContent
      },
      children: [{
          path: 'licenses',
          name: 'licenses',
          components: {
            geoContentView: Licenses
          },
          meta: {
            role: 'provider'
          },
          props: true
        },
        {
          path: 'products',
          name: 'products',
          components: {
            geoContentView: PublisherProducts
          },
          meta: {
            role: 'provider'
          },
          props: true
        },
        {
          path: 'distributors',
          name: 'my_distributors',
          components: {
            geoContentView: Distributors
          },
          meta: {
            role: 'provider'
          },
          props: true
        },
        {
          path: 'catalog',
          name: 'catalog',
          components: {
            geoContentView: Catalog
          },
          meta: {
            role: 'provider'
          },
          props: true,
          redirect: {
            name: 'maps'
          },
          children: [{
              path: 'enc',
              name: 'enc',
              components: {
                gcCatalogView: Enc
              },
              meta: {
                role: 'provider'
              },
              props: true,
              redirect: {
                name: 'list'
              },
              children: [{
                  name: 'list',
                  path: 'list',
                  components: {
                    encView: EncList
                  },
                  meta: {
                    role: 'provider'
                  },
                  props: {
                    encView: true
                  },
                },
                {
                  name: 'confirm',
                  path: 'confirm',
                  components: {
                    encView: Confirm
                  },
                  meta: {
                    role: 'provider'
                  },
                  props: {
                    encView: true
                  }
                }
              ]
            },
            {
              path: 's102',
              name: 's102',
              components: {
                gcCatalogView: S102
              },
              meta: {
                role: 'provider'
              },
              props: true,
              redirect: {
                name: 's102-list'
              },
              children: [{
                name: 's102-list',
                path: 'list',
                components: {
                  encView: S102List
                },
                meta: {
                  role: 'provider'
                },
                props: {
                  encView: true
                },
              }, ]
            },
            {
              path: 'poi',
              name: 'poi',
              components: {
                gcCatalogView: Poi
              },
              meta: {
                role: 'provider'
              },
              props: true
            },
            {
              path: 'ntm',
              name: 'ntm',
              components: {
                gcCatalogView: Ntm
              },
              meta: {
                role: 'provider'
              },
              props: true
            },
            {
              path: 'maps',
              name: 'maps',
              components: {
                gcCatalogView: Maps
              },
              meta: {
                role: 'provider'
              },
              props: true
            },
            {
              path: 'msg',
              name: 'msg',
              components: {
                gcCatalogView: NavMessages
              },
              meta: {
                role: 'provider'
              },
              props: true
            },
            {
              path: 'raw',
              name: 'raw',
              components: {
                gcCatalogView: Raw
              },
              meta: {
                role: 'provider'
              },
              props: true
            }
          ]
        },
      ]
    }]
  },
  {
    path: 'distributor',
    name: 'distributor',
    redirect: 'distributor/licenses',
    component: Distributor,
    meta: {
      role: 'distributor'
    },
    children: [{
        components: {
          distributorView: Products
        },
        name: 'distributor_products',
        path: 'products',
        meta: {
          role: 'distributor'
        }
      },
      {
        components: {
          distributorView: DistributorLicenses
        },
        name: 'distributor_licenses',
        path: 'licenses',
        meta: {
          role: 'distributor'
        }
      }
    ]
  },
  {
    path: 'reseller',
    name: 'reseller',
    component: Reseller,
    meta: {
      role: 'reseller'
    },
    // children: [{ component: Registartion, path: 'registartion' }]
  },
  {
    path: 'login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    //this is for redirect user to cabinet after login
    // beforeEnter: (to, from, next) => {
    //   let userRecord = localStorage.getItem('user');
    //   if (userRecord == null) {
    //     next()
    //   }
    //   else {
    //     let user = JSON.parse(userRecord)
    //     next({ path: "/" + user.roles[0].role.toLowerCase() });
    //   }
    // },
    meta: {
      guest: true
    }
  },
  {
    path: 'registration',
    name: 'registartion',
    component: Registartion,
    meta: {
      guest: true
    }
  },
  // {
  //   path: '/',
  //   redirect: to => {
  //     var user = JSON.parse(localStorage.getItem('user'));
  //     if (user) {
  //       return { path: "/" + user.roles[0].role.toLowerCase() };
  //     }
  //     else {
  //       return { path: '/login' }
  //     }
  //   }
  // },
  {
    path: '*',
    redirect: 'home'
  }
]