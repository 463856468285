<template>
  <div class="brd">
    <div>
      <strong>Assigned: </strong>
      <!-- <span v-if="assignedCar.nickname">{{ assignedCar.nickname }}</span>
      <span v-else>{{ assignedCar.deviceId }}</span> -->
      <driver-cart :car="assignedCar"></driver-cart>
    </div>
    <!-- <car-info :appState="assignedCar.appState"></car-info> -->
  </div>
</template>

<script>
import CarInfo from "./CarInfo.vue";
import DriverCart from "./DriverCart.vue"

export default {
  components: { DriverCart },
  props: {
    assignedCar: {},
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}
</style>