import myAxios from './myAxios';

export {
    getDistributor,
    issueLicense,
    getIssuedLicenses
};

function getDistributor() {
    return myAxios.get('/api/distributors/me');
}

function issueLicense({ email, prodid, exp, devnum }) {
    return myAxios.get('/api/distributors/issueLicense', { params: { email, prodid, exp, devnum } });
}

function getIssuedLicenses(prodid) {
    return myAxios.get('/api/distributors/issuedLicenses', { params: { prodid } });
}
