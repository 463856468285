<template>
  <div>
    <upload @uploaded="getNtms"></upload>
    <ntm-list class="pt-3" :ntms="ntms" @removeNtm="removeNtm"></ntm-list>
  </div>
</template>
<script>
import myAxios from "@/services/myAxios";

import NtmList from "./NtmList";
import Upload from "./Upload";

export default {
  components: { Upload, NtmList },
  data() {
    return { ntms: null };
  },
  computed: {
    gcid() {
      return this.$store.getters['geoContent/gcid'];
    },
  },  
  mounted(){
    this.getNtms();
  },
  methods: {
    getNtms() {
      myAxios
        .get("/api/ntm/GetNtms4GC?gcid=" + this.gcid)
        .then((resp) => {
          this.ntms = resp.data;
        })
    },
    removeNtm(id) {
      myAxios
        .delete("/api/ntm/" + id)
        .then(() => {
          this.getNtms();
        })
    }
  },
  watch: {
    gcid: function () {
      if (this.gcid) this.getNtms();
    },
  },
};
</script>