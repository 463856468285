<template>
   <div>
    <h5>Send Settings</h5>
    <div class="form-group">
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id="customFile"
          ref="file"
          @change="handleFileUpload"
        />
        <label class="custom-file-label" for="customFile"
          >Settings file</label
        >
      </div>
    </div>
    <div class="form-group mt-2">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="send"
        :disabled="bttnDisabled"
      >
        Send settings
      </button>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    deviceid: null,
  },
  data() {
    return {
      exchanges: null,
      file: null,
    };
  },
  created() {
    this.getExchanges();
  },
  computed: {
    bttnDisabled() {
      if (this.file) return false;
      return true;
    },
  },
  methods: {
    getExchanges() {
      myAxios
        .get("/api/teleapi/GetVoyagePlans", {
          params: { deviceId: this.deviceid, action: "voyageplan" },
        })
        .then((resp) => {
          this.exchanges = resp.data;
        })
    },
    send() {
      if (!this.file) return;

      var formData = new FormData();

      formData.append("deviceId", this.deviceid);
      formData.append("action", "settings");
      formData.append("file", this.file);

      myAxios
        .post("/api/teleapi/PostRequest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.getExchanges();
        });
    },
    checkState(exch) {
      this.$set(exch, "checkStateTxt", "checking...");
      myAxios
        .get("/api/teleapi/CheckVoyagePlanState", {
          params: { deviceId: this.deviceid, id: exch.id },
        })
        .then(() => {
          this.getExchanges();
          exch.checkStateTxt =
            "state updated " + new Date().toLocaleString("ru");
        })
        .catch(() => {
          exch.checkStateTxt = "no updates " + new Date().toLocaleString("ru");
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    getVoyagePlan(filename) {
      myAxios
        .get("/api/teleapi/GetVoyagePlan", {
          params: { deviceId: this.deviceid, filename: filename + ".json" },
        })
        .then((response) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(response.data))
          );
          element.setAttribute("download", filename);
          element.style.display = "none";
          element.click();
          URL.revokeObjectURL(element.href);
        })
    },
  },
};
</script>

<style scoped>
.custom-file {
  width: 300px;
  margin-top: 20px;
}

.filelink {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>