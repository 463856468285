<template>
  <div>
    <h5>Taxi Rates</h5>
    <table class="table table-sm" style="width: 700px">
      <tr>
        <td>Car Class</td>
        <td style="width: 200px">Rate per km</td>
        <td style="width: 200px">Min cost</td>
        <td></td>
      </tr>
      <tr v-for="rate in rates" :key="rate.id">
        <td>{{ rate.carClass }}</td>
        <td>{{ rate.rateForKm }}</td>
        <td>{{ rate.minimalCost }}</td>
        <td>
          <button
            class="btn btn-outline-danger btn-sm"
            @click="deleteRate(rate.id)"
          >
            Delete
          </button>
        </td>
      </tr>
      <tr v-if="missedClasses.length > 0">
        <td>
          <select v-model="newRate.carClass" class="form-control">
            <option value="" disabled selected>select class</option>
            <option
              v-for="cClass in missedClasses"
              :key="cClass"
              :value="cClass"
            >
              {{ cClass }}
            </option>
          </select>
        </td>
        <td>
          <input type="text" v-model="newRate.rateForKm" class="form-control" />
        </td>
        <td>
          <input
            type="text"
            v-model="newRate.minimalCost"
            class="form-control"
          />
        </td>
        <td>
          <button class="btn btn-outline-primary btn-sm" @click="saveRate()">
            Save Rate
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      rates: [],
      carClasses: ["econom", "comfort", "business", "premium"],
      newRate: {
        id: 0,
        rateForKm: null,
        minimalCost: null,
        carClass: "",
      },
    };
  },
  mounted() {
    this.loadRates();
  },
  computed: {
    missedClasses() {
      var existingClasses = this.rates.map((o) => o.carClass);
      return this.carClasses.filter((el) => !existingClasses.includes(el));
    },
  },
  methods: {
    loadRates() {
      myAxios.get("/api/account/rates").then((resp) => {
        this.rates = resp.data;
      });
    },
    saveRate() {
      myAxios.post("/api/account/rate", this.newRate).then(() => {
        this.loadRates();
        this.newRate = {
          id: 0,
          rateForKm: null,
          minimalCost: null,
          carClass: "",
        };
      });
    },
    deleteRate(id) {
      myAxios.delete("/api/account/rate/" + id).then(() => {
        this.loadRates();
      });
    },
  },
};
</script>