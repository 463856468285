<template>
  <div>
    <table class="table mx-auto w-auto">
      <tr>
        <th style="width:250px">DeviceId</th>
        <th style="width:150px">Nickname</th>
        <th>Driver Name</th>
        <th>Car Model</th>
        <th style="width:150px">Car Color</th>
        <th style="width:150px">Reg Number</th>
        <th style="width:150px">Class</th>
        <th>Child Seats Count</th>
        <th></th>
      </tr>
      <inst-details
        v-for="inst in installations"
        :key="inst.instId"
        :inst="inst"
        v-on:dataChanged="onDataChanged()"
      ></inst-details>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import InstDetails from "./inst-details.vue";

export default {
  components: { InstDetails },
  data() {
    return {
      installations: [],
    };
  },
  mounted() {
    this.getUsersInstallations();
  },
  methods: {
    getUsersInstallations() {
      myAxios.get("/api/installations/details").then((resp) => {
        this.installations = resp.data;
      });
    },
    onDataChanged() {
      this.getUsersInstallations();
    },
  },
};
</script>

<style>
.instDetailsTableRow {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
}
</style>