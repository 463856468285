<template>
  <div>
    <span v-if="orderState === 'executed'" class="badge badge-primary"
      >Выполняется</span
    >
    <span v-else-if="orderState === 'assigned'" class="badge badge-warning"
      >Ожидает подтверждения</span
    >
    <span v-else-if="orderState === 'no_cars'" class="badge badge-warning"
      >Поиск такси</span
    >
    <span v-else-if="orderState === 'done'" class="badge badge-success"
      >Выполнен</span
    >
    <span v-else-if="orderState === 'new'" class="badge badge-secondary"
      >Новый</span
    >
    <span v-else-if="orderState === 'sent'" class="badge badge-secondary"
      >Отправлен</span
    >
    <span v-else-if="orderState === 'cancelled'" class="badge badge-secondary"
      >Отменен</span
    >
  </div>
</template>

<script>
export default {
  props: {
    order: {},
  },
  computed: {
    orderState() {
    //   if (this.order && this.order.assignedCar) {
    //     var orderVoyagePlan = this.order.assignedCar.appState.voyageTasks.find(
    //       (o) => o.filename === this.order.assignedCar.voyagePlanId + ".json"
    //     );
    //     if (orderVoyagePlan) {
    //       if (orderVoyagePlan.status === "active") return "active";
    //       if (orderVoyagePlan.status === "done") return "done";
    //       return "waiting";
    //     }
    //     return "sent";
    //   }
    //   return "new";
    return this.order.state
    },
  },
};
</script>