<template>
  <div class="brd">
    <h6>New Order</h6>
    <div class="form-group">
      <label>PickUp Time</label>
      <div>
        <date-picker
          v-model="order.pickupTime"
          type="datetime"
          value-type="format"
          format="YYYY-MM-DD HH:mm"
          style="width: 185px"
          :show-second="false"
        ></date-picker>
      </div>
      <div>
        <ol>
          <li v-for="(point, index) in order.routePoints" :key="index">
            <span v-if="point.address">{{ point.address }}</span>
            <span v-else>{{ point.lat }} {{ point.lon }}</span>
          </li>
        </ol>
      </div>
      <div class="brd">
        <address-bar @setLocation="setLocation" :key="addressBarRefreshKey" />
        <button
          class="btn btn-outline-primary btn-sm"
          :disabled="!isPointComplete"
          @click="addPoint"
        >
          Add Point
        </button>
      </div>
      <div class="brd" v-if="calculation">
        <p>Дистанция: {{ calculation.distance.toFixed(1) }} км, Время в пути:
        {{ toTimeFormat(calculation.duration) }}</p>
        <button
          v-for="calc in calculation.priceList"
          :key="calc.carClass"
          class="btn btn-outline-primary btn-sm mr-2"
          :disabled="!isComplete"
          @click="saveOrder(calc.carClass, calc.price.toFixed(1))"
        >
          {{ calc.carClass }} <br> {{ calc.price.toFixed(1) }} {{calculation.currency}}
        </button>
      </div>

      <button class="btn btn-outline-secondary btn-sm" @click="cancel">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import AddressBar from "./AddressBar.vue";
import DatePicker from "vue2-datepicker";
import myAxios from "@/services/myAxios";
import axios from "axios";
import moment from "moment";
import map from "@/map2.js";

export default {
  components: { AddressBar, DatePicker },
  data() {
    return {
      order: {
        pickupTime: moment().add(5, "m").format("YYYY-MM-DD HH:mm"),
        routePoints: [],
      },
      currentPoint: {
        address: null,
        lat: null,
        lon: null,
      },
      addressBarRefreshKey: 0,
      calculation: null,
    };
  },
  computed: {
    isComplete() {
      return (
        this.order.routePoints.length >= 2 && this.order.pickupTime !== null
      );
    },
    isPointComplete() {
      return this.currentPoint.lat && this.currentPoint.lon;
    },
  },
  methods: {
    toTimeFormat(val) {
      var t = val.toString().split(':');
      return t[0] + ":" + t[1]
    },
    setLocation(loc) {
      this.currentPoint.address = loc.address;
      this.currentPoint.lat = Number(loc.latLon.lat).toFixed(6);
      this.currentPoint.lon = Number(loc.latLon.lon).toFixed(6);
    },
    addPoint() {
      this.order.routePoints.push({ ...this.currentPoint });
      this.currentPoint = {
        address: null,
        lat: null,
        lon: null,
      };
      this.addressBarRefreshKey++;
      this.showOnMap();
    },
    saveOrder(carClass, price) {
      myAxios
        .post(
          "/api/taxiorders",
          { ...this.order, carClass, price },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.$emit("orderCreated");
        });
    },
    cancel() {
      this.$emit("orderCreated");
    },
    showOnMap() {
      map.drawVPoints(this.order.routePoints);
      this.drawRoute();
    },
    drawRoute() {
      map.removeRoute();

      var goTos = [];
      this.order.routePoints.forEach((point) => {
        goTos.push({
          latitude: Number(point.lat),
          longitude: Number(point.lon),
        });
      });

      if (goTos.length > 1) {
        var routeCalcParams = {
          type: "OptimizeTime",
          allowYards: true,
          allowSideWays: false,
          allowGroundRoads: true,
          allowPaidRoads: true,
          allowRailwayCrossing: true,
          useJams: false,
          useLimitsUpd: true,
          useForecast: false,
        };

        var routes = [];
        routes.push({
          Points: goTos,
        });

        var request = {
          accessKey: "WWWB2BPROBKINET",
          getPoints: true,
          parameters: routeCalcParams,
          routes: routes,
          carParameters: null,
        };

        axios
          .post("https://api.probki.net/api/route", request, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            map.drawRoute(resp.data);
          });
      }
    },
    calculateOrder() {
      myAxios
        .post("/api/taxiorders/calculate", {
          routePoints: this.order.routePoints,
        })
        .then((resp) => {
          this.calculation = resp.data;
        });
    },
  },
  watch: {
    "order.routePoints": function () {
      if (this.order.routePoints.length > 1) {
        this.calculateOrder();
      } else {
        this.calculation = null;
      }
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}
</style>