<template>
  <div class="zone-item">
    <div class="pointer" @click="onZoneClick()">
      <div v-if="zone.name">{{ zone.name }}</div>
      <div v-else>
        <div class="form-group">
          <label for="priceModifier">Название зоны</label>
          <input type="text" class="form-control" v-model="name" />
        </div>
      </div>
    </div>
    <div v-if="isActive">
      <hr />
      <button class="btn btn-outline-primary btn-sm mr-2" @click="saveZone()" :disabled="!isComplete">
        Сохранить
      </button>
      <button class="btn btn-outline-danger btn-sm" @click="deleteZone()">Удалить</button>
    </div>
  </div>
</template>

<script>
import map from "@/map2.js";

export default {
  props: {
    zone: {},
    isActive: Boolean,
  },
  data() {
    return {
      name: this.zone.name
    };
  },
  computed:{
    isComplete(){
      if(this.name && this.zone.border) return true
      else return false
    }
  },
  methods: {
    onZoneClick() {
      map.panToZone(this.zone.border);
      this.$emit("onZoneClick", this.zone.id);
    },
    saveZone() {
      var zone = {
        id: this.zone.id,
        name: this.name
      };
      this.$emit("saveZone", zone);
    },
    deleteZone(){
      this.$emit("deleteZone", this.zone.id);
    }
  },
};
</script>

<style>
.zone-item {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}

.pointer {
  cursor: pointer;
}
</style>