<template>
  <div>
    <top-menu
      :deviceId="deviceId"
      :voyagePlans="voyagePlans"
      :currentVP="filename"
      v-on:setCurrentVP="setCurrentVP"
    ></top-menu>
    <div class="row">
      <div class="col-4 vpindex">
        <div v-if="appState">
          <strong>Текущее время</strong>
          <div>
            GMT: {{ fromOADate(appState.timestamp)
            }}<span v-if="appState.timestampLocal"
              ><br />Local: {{ fromOADate(appState.timestampLocal) }}</span
            >
          </div>
          <strong>Локация</strong>
          <div>
            <div
              v-if="appState.lat"
              class="clickable"
              @click="showOnMap(appState.lat, appState.lon)"
            >
              {{ appState.lat.toFixed(6) }} {{ appState.lon.toFixed(6) }}
              <span v-if="appState.isSumulator"> [Simulator]</span
              ><span v-else> [GPS]</span>
              <div v-if="appState.address">{{ appState.address }}</div>
            </div>
          </div>
        </div>
        <vp-index
          :vp="vp"
          :activeCmd="cmd"
          v-on:setCurrentCmd="setCurrentCmd"
        ></vp-index>
      </div>
      <div class="col-8">
        <div><mymap></mymap></div>
        <div><vp-cmd :cmd="cmd"></vp-cmd></div>
      </div>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import map from "@/map2.js";
import { eventBus } from "@/main";
import moment from "moment";

import TopMenu from "./top-menu";
import VpIndex from "./vp-index";
import Map from "./Map.vue";
import VpCmd from "./vp-cmd.vue";

export default {
  components: {
    TopMenu,
    VpIndex,
    mymap: Map,
    VpCmd,
  },
  props: {
    deviceId: null,
    filename: null,
  },
  data() {
    return {
      voyagePlans: null,
      vp: null,
      cmd: null,
      polling: null,
      pointsDrawed: false,
    };
  },
  mounted() {
    this.getVoyagePlans();

    eventBus.$on("selectVPcmd", (data) => {
      this.selectVpCmdByLatLon(data.latlng);
    });

    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  computed: {
    appState() {
      if (this.vp && this.vp.carInfo) {
        return this.vp.carInfo;
      }
      return null;
    },
    vpGoToCmds() {
      var goToCmds = [];
      this.vp.items.forEach((itm) => {
        itm.commands.forEach((cmd) => {
          if (cmd.type === "goTo") {
            goToCmds.push(cmd);
          }
        });
      });
      return goToCmds;
    },
  },
  methods: {
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
    pollData() {
      this.polling = setInterval(() => {
        this.showVoyagePlan(this.filename);
      }, 30000);
    },
    getVoyagePlans() {
      myAxios
        .get("/api/teleapi/GetVoyagePlans", {
          params: { deviceId: this.deviceId, action: "voyageplan" },
        })
        .then((resp) => {
          this.voyagePlans = resp.data;
          this.showVoyagePlan(this.filename);
        })
    },
    showVoyagePlan(filename) {
      myAxios
        .get("/api/teleapi/GetVoyagePlan", {
          params: { deviceId: this.deviceId, filename },
        })
        .then((response) => {
          this.vp = response.data;
          this.showAllVPoints();
        })
    },
    setCurrentVP(filename) {
      this.currentVP = filename;
      this.showVoyagePlan(filename);
    },
    setCurrentCmd(cmd) {
      this.cmd = cmd;
    },
    showAllVPoints() {
      var points = [];

      if (this.vpGoToCmds) {
        this.vpGoToCmds.forEach((cmd) => {
          points.push({
            lat: cmd.lat,
            lon: cmd.lon,
            ttt: cmd.time_state ? cmd.time_state : "n/a",
          });
        });
      }

      if (!this.pointsDrawed) {
        map.drawVPoints(points);
        this.pointsDrawed = true;
      }
    },
    selectVpCmdByLatLon(latLng) {
      var clickedCmd = this.vpGoToCmds.find(
        (cmd) => cmd.lat == latLng.lat && cmd.lon == latLng.lng
      );

      this.cmd = clickedCmd;
    },
    calculateDelay(planned, eta) {
      var diff = new Date(eta) - new Date(planned);

      if (diff > 0) {
        return "delay " + moment.duration(Math.abs(diff)).asMinutes() + " min";
      } else if (diff < 0) {
        return "ahead " + moment.duration(Math.abs(diff)).asMinutes() + " min";
      } else {
        return "in time";
      }
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
  },
};
</script>

<style scoped>
.vpindex {
  overflow-y: scroll;
}

.clickable {
  cursor: pointer;
  color: blue;
}
</style>