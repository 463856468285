<template>
  <div class="row">
    <div class="col-md-6">
      <h4 class="mb-4">{{ $t('login.title') }}</h4>
      <form @submit.prevent="handleSubmit" class="form">
        <div class="form-group">
          <label for="username">Email</label>
          <input class="form-control" type="email" name="email" v-model="username" />
        </div>
        <div class="form-group">
          <label for="password">{{ $t('login.password') }}</label>
          <input class="form-control" type="password" name="password" v-model="password" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary mr-3">{{ $t('login.btn.name') }}</button>
          <span class="reg-link" @click="switchMode('register')">{{ $t('login.register') }}</span> |
          <span class="reg-link" @click="switchMode('repassword')">{{ $t('login.forgot.password') }}</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: ""
    };
  },
  computed: {
    email() {
      return this.$store.state.auth.registration
        ? this.$store.state.auth.registration.email
        : null;
    }
  },
  methods: {
    handleSubmit() {
      if (this.username && this.password) {
        this.$store.dispatch("auth/login", {
          username: this.username,
          password: this.password
        });
      }
    },
    switchMode(mode) {
      this.$emit("changeMode", mode);
    }
  },
  created() {
    if (this.email) this.username = this.email;
  }
};
</script>

<style scoped>
.reg-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>