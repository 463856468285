import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth'
import { alert } from './modules/alert'
import { user } from './modules/user'
import { provider } from './modules/provider'
import { distributor } from './modules/distributor'
import { geoContent } from './modules/geoContent'
import { chart } from './modules/chart'
import { mapsCollections } from './modules/mapsCollections'
import { s102 } from './modules/s102'
import { shop } from './modules/shop'
import { accountSettings } from "./modules/accountSettings"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: {}
  },
  getters: {
    locale(state) {
      return state.locale
    }
  },
  mutations: {
    setLocale(state, payload) {
      state.locale = payload
    }
  },
  actions: {
    setLocale({ commit }, payload) {
      commit('setLocale', payload)
    }
  },
  modules: {
    auth,
    alert,
    user,
    provider,
    distributor,
    geoContent,
    chart,
    shop,
    s102,
    mapsCollections,
    accountSettings
  }
})
