<template>
  <div>
    <h4>{{ $t("userlicenses.licenses") }}</h4>
    <table v-if="licenses" class="table table-sm">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("userlicenses.title") }}</th>
          <th>{{ $t("userlicenses.product") }}</th>
          <th>{{ $t("userlicenses.alowed_devs") }}</th>
        </tr>
      </thead>
      <tr v-for="(lic, index) in licenses" :key="lic.id">
        <td>{{ index + 1 }}.</td>
        <td>
          <span>{{ lic.product.geoContent.title }}</span>
        </td>
        <td>
          {{ prodShort(lic.product) }}
        </td>
        <td>
          <div @click="expandedId = lic.id" class="clickable">
            <u
              >{{ lic.installations.length }} {{ $t("userlicenses.of") }}
              {{ lic.deviceCount }}</u
            >
          </div>
          <div v-if="expandedId === lic.id" class="row">
            <div class="col-9">
              <table class="table table-sm">
                <tr v-for="(inst, indx) in lic.installations" :key="inst.id">
                  <td>{{ indx + 1 }}.</td>
                  <td>{{ inst.platform.name }}</td>
                  <td>{{ inst.deviceId }}</td>
                  <td>{{ inst.nickname }}</td>
                </tr>
              </table>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  name: "UserLicenses",
  props: {
    licenses: null,
  },
  data() {
    return {
      expandedId: 0,
    };
  },
  methods: {
    setCurrent(gcid, userid) {
      myAxios
        .get("/api/users/setCurrentGC", {
          params: { gcid, userid },
        })
        .then(() => {
          this.$router.go();
        });
    },
    prodShort(prod) {
      var short = prod.licType + " [";
      if (prod.enc) short += "ENC, ";
      if (prod.ais) short += "AIS, ";
      if (prod.ntm) short += "NTM, ";
      if (prod.poi) short += "POI, ";
      if (prod.weather) short += "WTHR, ";
      if (prod.navMsg) short += "NMSG, ";
      short = short.slice(0, -2).toUpperCase();
      return short;
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: blue;
}
</style>>
