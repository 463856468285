<template>
  <div>
    <div class="brdbtm">
      {{ fromOADate(appState.timestampLocal) }}
      <div
        v-if="appState.lat"
        class="clickable"
        @click="showOnMap(appState.lat, appState.lon)"
      >
        <span v-if="appState.address">{{ appState.address }}</span>
        <span v-else
          >{{ appState.lat.toFixed(6) }} {{ appState.lon.toFixed(6) }}</span
        >
      </div>
    </div>
    <div>
      <div v-if="activeVP">
        {{ activeVP.name ? activeVP.name : activeVP.filename }}
        | status: {{ activeVP.status }} | ETA:
        {{ mmoment(appState.voyageTasks[0].estimatedEndTimeLocal) }}
      </div>
      <span v-else>no active task <span v-if="tasksCount"> / </span></span>
      <span v-if="tasksCount">tasks total: {{ tasksCount }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import map from "@/map2.js";

export default {
  props: {
    appState: {},
  },
  computed: {
    activeVP() {
      if (this.appState.voyageTasks && this.appState.voyageTasks.length > 0) {
        var avp = this.appState.voyageTasks.find((o) => o.status === "active");
        if (avp) return avp;
      }
      return null;
    },
    tasksCount() {
      if (this.appState.voyageTasks && this.appState.voyageTasks.length > 0) {
        return this.appState.voyageTasks.length;
      }
      return 0;
    },
  },
  methods: {
    mmoment(strDateTime) {
      return moment(strDateTime).format();
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}

.brdbtm {
  font-size: 14px;
  border-bottom: solid 1px #ced4da;
}

.clickable {
  cursor: pointer;
  color: rgb(37, 37, 121);
}
</style>