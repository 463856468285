<template>
  <div class="card">
    <div class="card-body">
      <span v-if="planItem.state === 'new'" class="badge badge-primary"
        >формируется</span
      >
      <span v-else-if="planItem.state === 'planned'" class="badge badge-primary"
        >запланировано</span
      >
      <div class="form-group mt-2">
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="planItem.name"
          placeholder="Название"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="planItem.comment"
          placeholder="Детали"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="planItem.address"
          placeholder="Адрес"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="planItem.action"
          placeholder="Действие"
        />
      </div>
      <div class="form-group">
        <select class="form-control form-control-sm" v-model="planItem.type">
          <option value="goTo">Ехать к</option>
        </select>
      </div>
      <div class="form-group">
        <button class="btn btn-outline-danger btn-sm" @click="delItem">
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import map from "@/map2.js";

export default {
  props: {
    planItem: {},
  },
  created() {
    if (this.planItem.lat && this.planItem.lon) {
      this.addPoint2Map(this.planItem.lat, this.planItem.lon);
    }
  },
  methods: {
    addPoint2Map() {
      if (this.planItem.lat && this.planItem.lon) {
        if (this.planItem.marker) {
          map.removeMarker(this.planItem.marker);
          this.planItem.marker = null;
        }
        this.planItem.marker = map.addMarker(
          this.planItem.lat,
          this.planItem.lon,
          this.planItem.id
        );
      }
    },
    delItem() {
      map.removeMarker(this.planItem.marker);
      this.$emit("delItem", this.planItem.id);
    },
  },
  // watch: {
  //   "planItem.lat": function () {
  //     this.addPoint2Map(this.planItem.lat, this.planItem.lon);
  //   },
  //   "planItem.lon": function () {
  //     this.addPoint2Map(this.planItem.lat, this.planItem.lon);
  //   },
  // },
};
</script>