import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './localization/i18n'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import LocLink from './components/LocLink.vue'
import MyDate from './components/MyDate.vue'

export const eventBus = new Vue()

Vue.use(Notifications)

Vue.config.productionTip = false

Vue.component('loc-link', LocLink);
Vue.component('my-date', MyDate);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
