<template>
  <div class="mb-2" id="add_permit">
    <form class="p-3" v-on:submit.prevent>
      <div class="row">
        <div class="form-group col-md-8">
          <label class="form-label">Email</label>
          <input type="email" class="form-control" v-model="email" id="email" placeholder="Email" />
        </div>
        <div class="col-md-4">
          <label class="form-label">{{ $t('distributor.add_lic.dev_num') }}</label>
          <input
            type="number"
            min="0"
            class="form-control"
            v-model="devnum"
            id="exp"
            placeholder="0"
            style="width:90px"
          />
          <small class="form-text text-muted">{{ $t('distributor.add_lic.unlim') }}</small>
        </div>
      </div>
      <button type="submit" @click="addPermit" class="btn btn-primary">{{ $t('distributor.add_lic.issue') }}</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    prod: null
  },
  data() {
    return {
      email: "",
      exp: "12",
      devnum: "0"
    };
  },
  methods: {
    addPermit() {
      if (this.email && this.prod) {
        this.$store.dispatch("distributor/issueLicense", {
          email: this.email,
          prodid: this.prod.product.id,
          exp: this.exp,
          devnum: this.devnum
        });
        this.email = "";
        this.devnum = 0;
      }
    }
  }
};
</script>
<style scoped>
#add_permit {
  background-color: #eee;
}
</style>