import * as s102Service from '@/services/chartService'
const initialState = {
    s102Collection: null,
    status: null,
}

export const s102 = {
    namespaced: true,
    state: initialState,
    actions: {
        getS102Collection({ dispatch, commit, rootGetters }) {
            commit('s102CollectionRequest');
            var gcid = rootGetters['provider/gcid']
            s102Service.getS102Collection(gcid)
                .then(resp => {
                    var s102Collection = resp.data;
                    if (s102Collection) {
                        commit('s102CollectionSuccess', s102Collection);
                    }
                    else {
                        commit('s102CollectionFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('s102CollectionFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        s102CollectionRequest(state) {
            state.status = { loading: true };
            state.s102Collection = null;
        },
        s102CollectionSuccess(state, s102Collection) {
            state.status = { loaded: true };
            state.s102Collection = s102Collection;
        },
        s102CollectionFailure(state) {
            state.status = null;
            state.s102Collection = null;
        },
    }
}