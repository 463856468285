<template>
  <div class="row p-0 m-0">
    <!-- <div class="col-md-2">
      <p>WebMap Last Update: {{mmoment(webMapTimeStamp)}}</p>
      <p>MapData Last Update: {{mmoment(gcTimeStamp)}}</p>
      <p v-if="isOld">WebMap update in progress. Please refresh this page in a minute.</p>
      <p v-else>WebMap is up to date.</p>
    </div> -->
    <div class="col-md-10" id="mymap" ref="mm" :style="{ height: windowHeight + 'px' }"></div>
  </div>
</template>

<script>
import map from "@/map.js";
import "leaflet/dist/leaflet.css";
//import myAxios from "@/services/myAxios";
//import moment from "moment";

export default {
  props: ["taskId"],
  data() {
    return {
      windowHeight: window.innerHeight,
      // isOld: false,
      // webMapTimeStamp: null,
      // gcTimeStamp: null
    };
   },
  // computed: {
  //   provider() {
  //     return this.$store.state.provider.provider;
  //   },
  //   geoContent() {
  //     if (this.provider && this.provider.geoContents.length > 0) {
  //       return this.provider.geoContents[0];
  //     }
  //     return null;
  //   }
  // },
  // created() {
  //   this.$store.dispatch("provider/getProvider");
  // },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });

    map.init(this.$refs.mm, this.taskId);
  },
  // methods: {
  //   checkUpd() {
  //     this.webMapTimeStamp = moment.utc(this.getTilesUpDate());
  //     var tid = this.getRenderTaskId();

  //     this.gcTimeStamp = moment(this.geoContent.upDate);
  //     if (moment(this.webMapTimeStamp).isBefore(moment(this.gcTimeStamp))) {
  //       myAxios.get("http://84.52.88.202:8091/update");
  //       this.isOld = true;
  //     }
  //   },
  //   getRenderTaskId() {
  //     myAxios
  //       .get("api/geocontent/gotomap?gcid=" + this.geoContent.id)
  //       .then(resp => {
  //         return resp.data;
  //       });
  //   },
  //   getTilesUpDate() {
  //     myAxios.get("http://84.52.88.202:8091/mintime").then(resp => {
  //       return resp.data;
  //     });
  //   },
  //   mmoment(date) {
  //     if (date) return moment(date).format("YYYY-MM-DD HH:mm:ss");
  //     return "unlimited";
  //   }
  // },
  // watch: {
  //   geoContent: function() {
  //     this.gcTimeStamp = moment(this.geoContent.upDate);
  //     this.checkUpd();
  //   }
  // }
};
</script>