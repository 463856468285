import myAxios from './myAxios';

export {
    getChartCollection,
    getS102Collection
};

function getChartCollection(gcid) {
    return myAxios.get('/charts/getCollection?gcid=' + gcid);
}

function getS102Collection(gcid) {
    return myAxios.get('/charts/getS102Collection?gcid=' + gcid);
}

