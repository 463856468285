<template>
  <div v-if="routeInfo" class="card">
    <div class="card-body">
      <h6>Параметры маршрута</h6>
      <table class="table table-sm">
        <tr>
          <td>Точек на маршруте:</td>
          <td>{{ routeInfo.pointsCount }}</td>
        </tr>
        <tr>
          <td>Длина маршрута:</td>
          <td>{{ (routeInfo.totalDistance / 1000).toFixed(1) + " км" }}</td>
        </tr>
        <tr>
          <td>Время в пути:</td>
          <td>{{ eta }}</td>
        </tr>
      </table>
      <button class="btn btn-outline-primary btn-sm" @click="postRoutePlan">Отправить</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routeInfo: null,
  },
  computed: {
    eta() {
      var date = new Date(null);
      date.setSeconds(this.routeInfo.totalTime); // specify value for SECONDS here
      return date.toISOString().substr(11, 8);
    },
  },
  methods: {
    postRoutePlan() {
      this.$emit("postRoutePlan");
    },
  },
};
</script>