<template>
  <div v-if="distributor">
    <p>
      <strong>{{distributor.title}}</strong> {{ $t('distributor.home.title') }}
    </p>
    <hr />
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <loc-link
          :to="{name: 'distributor_licenses'}"
          class="nav-link"
          :aclass="'active'"
          :class="{disabled: !hasProds}"
        >{{ $t('distributor.home.lics') }}</loc-link>
      </li>
      <li class="nav-item">
        <loc-link
          :to="{name: 'distributor_products'}"
          class="nav-link"
          :aclass="'active'"
        >{{ $t('distributor.home.prods') }}</loc-link>
      </li>
    </ul>
    <router-view name="distributorView"></router-view>
  </div>
</template>

<script>
export default {
  name: "DistributorCabinet",
  created() {
    this.$store.dispatch("distributor/getDistributor");
  },
  computed: {
    status() {
      return this.$store.state.distributor.status;
    },
    distributor() {
      return this.$store.state.distributor.distributor;
    },
    hasProds() {
      if (this.distributor.distributorsProducts.length > 0) return true;
      return false;
    }
  }
};
</script>