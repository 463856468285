<template>
  <div>
    <table class="table mt-4" v-if="products">
      <thead>
        <tr>
          <th style="width:10%">{{ $t('publisher.gc.prods.tbl.type') }}</th>
          <th style="width:8%" class="text-center">{{ $t('publisher.gc.prods.tbl.enc') }}</th>
          <th style="width:8%" class="text-center">S-102</th>
          <th style="width:8%" class="text-center">POI</th>
          <th style="width:8%" class="text-center">Ntm</th>
          <th style="width:8%" class="text-center">AIS</th>
          <th style="width:8%" class="text-center">{{ $t('publisher.gc.prods.tbl.wthr') }}</th>
          <th style="width:8%" class="text-center">{{ $t('publisher.gc.prods.tbl.noaa_wthr') }}</th>
          <th style="width:8%" class="text-center">Nav Messages</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tr is="product-item" v-for="prod in products" :key="prod.licType" :prod="prod"></tr>
    </table>
  </div>
</template>

<script>
import ProductItem from "@/components/product/ProductItem";
import { sortBy } from "@/helpers/sortBy";

export default {
  components: {
    ProductItem
  },
  computed: {
    products() {
      if (this.geoContent) {
        var prodArr = [];
        var prods = this.geoContent.products;
        var prodTypes = ["com", "corp", "free"];
        prodTypes.forEach(pt => {
          var cur_s = prods.find(prod => prod.licType === pt);
          if (cur_s) {
            prodArr.push(cur_s);
          } else {
            prodArr.push({ licType: pt, id: 0 });
          }
        });
        prodArr = prodArr.sort(sortBy("id", "desc"));
        return prodArr;
      }
      return null;
    },
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    }
  }
  // watch: {
  //   geoContent() {}
  // }
};
</script>