<template>
  <tr v-if="mode === 'display'">
    <td>{{ inst.deviceId }}</td>
    <td>{{ inst.nickname }}</td>
    <template v-if="inst.details">
      <td>{{ inst.details.driverName }}</td>
      <td>{{ inst.details.carModel }}</td>
      <td>{{ inst.details.carColor }}</td>
      <td>{{ inst.details.carNumber }}</td>
      <td>{{ inst.details.carClass }}</td>
      <td>{{ inst.details.childSeatsCount }}</td>
      <td>
        <button class="btn btn-outline-danger btn-sm" @click="deleteDetails">
          Delete
        </button>
      </td>
    </template>
    <td colspan="7" v-else>
      <button class="btn btn-outline-primary btn-sm" @click="mode = 'create'">
        Add
      </button>
    </td>
  </tr>
  <tr v-else-if="mode === 'create'">
    <td>{{ inst.deviceId }}</td>
    <td>{{ inst.nickname }}</td>
    <td class="form-group">
      <input
        type="text"
        v-model="newDetails.driverName"
        class="form-control"
      />
    </td>
    <td class="form-group">
      <input type="text" v-model="newDetails.carModel" class="form-control" />
    </td>
    <td class="form-group">
      <input type="text" v-model="newDetails.carColor" class="form-control" />
    </td>
    <td class="form-group">
      <input
        type="text"
        v-model="newDetails.carNumber"
        class="form-control"
      />
    </td>
    <td class="form-group">
      <select v-model="newDetails.carClass" class="form-control">
        <option value="" disabled selected>select type</option>
        <option value="econom">econom</option>
        <option value="comfort">comfort</option>
        <option value="business">business</option>
      </select>
    </td>
    <td class="form-group">
      <input
        type="text"
        v-model="newDetails.childSeatsCount"
        class="form-control"
      />
    </td>
    <td>
      <button class="btn btn-outline-primary btn-sm mr-1" @click="save()">Save</button>
      <button class="btn btn-outline-secondary btn-sm" @click="cancel()">Cancel</button>
    </td>
  </tr>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    inst: {},
  },
  data() {
    return {
      mode: "display",
      newDetails: {
        instId: this.inst.instId,
        profileType: "taxi",
        carClass: ""
      },
    };
  },
  methods: {
    save() {
      myAxios.post("/api/installations/details", this.newDetails).then(() => {
        this.$emit("dataChanged");
        this.mode = "display";
      });
    },
    cancel() {
      this.newDetails = {
        instId: this.inst.instId,
        profileType: "taxi",
        carClass: ""
      };
      this.mode = "display";
    },
    deleteDetails() {
      myAxios
        .delete("/api/installations/details/" + this.inst.details.id)
        .then(() => {
          this.$emit("dataChanged");
        });
    },
  },
};
</script>

<style>
.column {
  display: table-cell;
}
</style>