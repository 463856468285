export const SUPPORTED_LOCALES = [{
    code: 'en',
    base: '',
    flag: 'us',
    name: 'English',
    translations: '/translations/en.json'
}, {
    code: 'ru',
    base: '/ru',
    flag: 'ru',
    name: 'Русский',
    translations: '/translations/ru.json'
}]