<template>
  <tr :class="{ inactive: prod.id == 0 || !prod.isEnabled }">
    <td>
      {{ $t("publisher.gc.prods.itm." + prod.licType) }}
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" checked disabled v-model="prod.enc" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.s102" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.poi" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.ntm" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.ais" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.weather" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.noaaWeather" />
      </div>
    </td>
    <td>
      <div class="form-check text-center">
        <input class="form-check-input" type="checkbox" v-model="prod.navMsg" />
      </div>
    </td>
    <td>
      <div v-if="prod.licType==='com'" class="form-group row m-0">
        <label class="col-sm-3 col-form-label">Price</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model="prod.price" />
        </div>
      </div>
    </td>
    <td>
      <div v-if="prod.id">
        <div v-if="prod.isEnabled" class="btn-group btn-group-toggle" data-toggle="buttons">
          <button class="btn btn-primary btn-sm" @click="handleSubmit(true)">{{ $t("publisher.gc.prods.itm.update") }}</button>
          <button class="btn btn-warning btn-sm" @click="handleSubmit(false)">{{ $t("publisher.gc.prods.itm.disable") }}</button>
        </div>
        <div v-else>
          <button class="btn btn-info btn-sm" @click="handleSubmit(true)">{{ $t("publisher.gc.prods.itm.enable") }}</button>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-sm btn-primary" @click="handleSubmit(true)">{{ $t("publisher.gc.prods.itm.create") }}</button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    prod: {}
  },
  computed: {
    gcid_comp() {
      return this.$store.state.geoContent.geoContent.id;
    }
  },
  methods: {
    handleSubmit(enabled) {
      this.prod.isEnabled = enabled;
      this.prod.enc = true;
      if (!this.prod.gcid) this.prod.gcid = this.gcid_comp;
      this.prod.geoContentId = this.gcid_comp;
      this.$store.dispatch("geoContent/addUpdateProduct", this.prod);
    }
  }
};
</script>
<style scoped>
.inactive {
  background-color: #ebebeb;
  color: rgb(138, 138, 138);
}
</style>>

