<template>
  <div class="brd">
    <div v-for="car in cars" :key="car.deviceId" class="brd">
      <div>
        <span v-if="car.nickname">{{ car.nickname }}</span>
        <span v-else>{{ car.deviceId }}</span>
      </div>
      <car-info :appState="car.appState"></car-info>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import CarInfo from "./CarInfo.vue";

export default {
  components: { CarInfo },
  data() {
    return {
      cars: [],
    };
  },
  mounted() {
    this.loadCars();
  },
  methods: {
    loadCars() {
      myAxios.get("/api/taxiorders/cars").then((resp) => {
        this.cars = resp.data;
      });
    },
  },
};
</script>

<style scoped>
.brd {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
}
</style>