<template>
  <div>
    <h5 class="my-3">{{ $t('publisher.gc.distr.pageHeader') }}</h5>
    <table v-if="requests" class="table table-sm">
      <thead>
        <tr>
          <th>{{ $t('publisher.gc.distr.title') }}</th>
          <th>{{ $t('publisher.gc.distr.product') }}</th>
          <th>{{ $t('publisher.gc.distr.status') }}</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="req in requests" :key="req.id">
        <td>{{req.distributor.title}}</td>
        <td>{{prodShort(req.product)}}</td>
        <td>{{ $t('publisher.gc.distr.' + req.status) }}</td>
        <td>
          <span v-if="req.status === 'pending'">
            <button
              class="btn btn-sm btn-success mx-2"
              @click="changeDistribStatus(req.id, 'accepted')"
            >{{ $t('publisher.gc.distr.accept') }}</button>
            <button
              class="btn btn-sm btn-danger"
              @click="changeDistribStatus(req.id, 'rejected')"
            >{{ $t('publisher.gc.distr.reject') }}</button>
          </span>
          <span v-if="req.status === 'accepted'">
              <button
              class="btn btn-sm btn-warning mx-2"
              @click="changeDistribStatus(req.id, 'paused')"
            >{{ $t('publisher.gc.distr.pause') }}</button>
            <button
              class="btn btn-sm btn-danger"
              @click="changeDistribStatus(req.id, 'rejected')"
            >{{ $t('publisher.gc.distr.reject') }}</button>
          </span>
          <span v-if="req.status === 'paused'">
              <button
              class="btn btn-sm btn-success mx-2"
              @click="changeDistribStatus(req.id, 'accepted')"
            >{{ $t('publisher.gc.distr.reopen') }}</button>
            <button
              class="btn btn-sm btn-danger"
              @click="changeDistribStatus(req.id, 'rejected')"
            >{{ $t('publisher.gc.distr.reject') }}</button>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return { requests: null };
  },
  computed: {
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    }
  },
  methods: {
    getRequests() {
      myAxios
        .get("/api/providers/DistribRequests?gcid=" + this.geoContent.id)
        .then(resp => {
          this.requests = resp.data;
        });
    },
    changeDistribStatus(reqid, status) {
      myAxios
        .get("/api/providers/ChangeDistribRequestStatus", {
          params: { reqid, status }
        })
        .then(resp => {
          this.getRequests();
        });
    },
    prodShort(prod){
      var short = prod.licType.toUpperCase() + " [";
      if(prod.enc) short += "ENC, "
      if(prod.ais) short += "AIS, "
      if(prod.ntm) short += "NTM, "
      if(prod.poi) short += "POI, "
      if(prod.weather) short += "WTHR, "
      if(prod.navMsg) short += "NMSG, "
      short = short.slice(0, -2);
      short += "]"
      return short
    }
  },
  mounted() {
    this.getRequests();
  }
};
</script>