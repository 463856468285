import myAxios from './myAxios';

export {
    getMapsCollections,
};

function getMapsCollections(gcid) {
    return myAxios.get('/maps/getCollections?gcid=' + gcid);
}

