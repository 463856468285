<template>
  <div id="mymap" ref="mm" :style="{ height: 500 + 'px' }"></div>
</template>

<script>
import map from "@/map2.js";
import "leaflet/dist/leaflet.css";

export default {
  mounted() {
    map.init(this.$refs.mm);
  },
};
</script>

<style scoped>
#mymap {
  border: solid 1px dimgray;
}
</style>