<template>
  <div v-if="cmd">
    <div><strong>Type:</strong> {{ cmd.type }}</div>
    <div><strong>State:</strong> {{ cmd.state }}</div>
    <div><strong>Action:</strong> {{ cmd.action }}</div>
    <div><strong>Address:</strong> {{ cmd.address }}</div>
    <div>
      <strong>Location: </strong>
      <span
        class="clickable"
        v-if="cmd.lat"
        @click="showOnMap(cmd.lat, cmd.lon)"
        >{{ Number(cmd.lat).toFixed(6) }} {{ Number(cmd.lon).toFixed(6) }}</span
      >
    </div>
    <div>
      <strong>Planned Time: </strong>
      {{ new Date(cmd.plannedTimeStart).toLocaleString("ru") }}
      <span v-if="cmd.plannedTimeEnd">
        - {{ new Date(cmd.plannedTimeEnd).toLocaleTimeString("ru") }}</span
      >
    </div>
    <div v-if="cmd.estimatedTime">
      <strong>ETA:</strong>
      {{ new Date(cmd.estimatedTime).toLocaleString("ru") }}
    </div>
    <div v-if="cmd.completedTime">
      <strong>Fact:</strong>
      {{ new Date(cmd.completedTime).toLocaleString("ru") }}
    </div>
    <div v-if="cmd.estimatedTime && cmd.plannedTimeStart">
      <strong
        >Delta: </strong>
        {{ calculateDelay(cmd.plannedTimeStart, cmd.estimatedTime) }}
    </div>
  </div>
</template>

<script>
import map from "@/map2.js";
import moment from "moment";

export default {
  props: {
    cmd: null,
  },
  methods: {
    showOnMap(lat, lon) {
      map.panTo(lat, lon);
    },
    calculateDelay(planned, eta) {
      if (planned && eta) {
        var diff = new Date(eta) - new Date(planned);

        if (diff > 0) {
          return (
            "delay " +  moment.duration(Math.abs(diff)).asMinutes() + " min"
          );
        } else if (diff < 0) {
          return (
            "ahead " + moment.duration(Math.abs(diff)).asMinutes() + " min"
          );
        } else {
          return "in time";
        }
      }
      return null;
    },
  },
};
</script>