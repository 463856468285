<template>
  <div v-if="carinfo">
    <span class="clickable" @click="showOnMap(carinfo.lat, carinfo.lon)">
      {{ carinfo.lat }} {{ carinfo.lon }}
    </span>
  </div>
</template>

<script>
import map from "@/map2.js";

export default {
  props: {
    carinfo: null,
  },
  methods: {
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
  color: blue;
}
</style>