var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('notifications'),(this.$route.name !== 'map')?_c('nav',{staticClass:"navbar navbar-light mb-3",staticStyle:{"background-color":"#e3f2fd","padding":"0"}},[_c('loc-link',{staticClass:"ml-3 navbar-brand clickable",attrs:{"to":'/'}},[_c('span',{staticClass:"align-bottom ml-2"},[_c('strong',[_vm._v("bGeo")])])]),(_vm.auth.status.loggedIn)?_c('form',{staticClass:"form-inline user-roles",on:{"submit":function($event){$event.preventDefault();}}},[_c('loc-link',{class:_vm.cabBtnClass('user'),attrs:{"rltag":'button',"to":'/user'}},[_vm._v(_vm._s(_vm.$t("nav.user")))]),_c('loc-link',{class:_vm.cabBtnClass('provider'),attrs:{"rltag":'button',"to":'/provider'}},[_vm._v(_vm._s(_vm.$t("nav.publisher")))])],1):_vm._e(),_c('span',{staticClass:"navbar-text mr-3"},[(_vm.auth.status.loggedIn)?_c('span',[_vm._v(" "+_vm._s(_vm.auth.user.email)+" "),_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("main.nav.logout"))+" ")])]):_vm._e(),_c('span',{staticClass:"ml-5"},[_c('router-link',{attrs:{"to":_vm.path}},[_vm._v("EN")]),_vm._v(" | "),_c('router-link',{attrs:{"to":'/ru' + _vm.path}},[_vm._v("RU")])],1)])],1):_vm._e(),_c('notifications'),(
      this.$route.name !== 'map' &&
      this.$route.name !== 'route-plan' &&
      this.$route.name !== 'voyageplaneditor' &&
      this.$route.name !== 'taxiorders' &&
      this.$route.name !== 'installations' &&
      this.$route.name !== 'zoneeditor' &&
      this.$route.name !== 'teleapi'
    )?_c('div',{staticClass:"container"},[(_vm.alert.message)?_c('div',[_vm._v(_vm._s(_vm.alert.message))]):_vm._e(),_c('router-view')],1):_c('div',{staticClass:"container-fluid"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }