<template>
  <div>
    <div class="row">
      <div class="col-md-4 m-0 pr-0 pl-2">
        <div :style="{ height: windowHeight + 'px' }" class="orders-index">
          <button
            class="btn btn-outline-secondary btn-sm ml-2"
            @click="refresh"
          >
            Refresh
          </button>
          <div>
            <drivers :key="driversKey" />
          </div>
        </div>
      </div>
      <div class="col-md-8 p-0"><mymap></mymap></div>
    </div>
  </div>
</template>

<script>
import Map from "./Map.vue";
import map from "@/map2.js";
import Drivers from "./Drivers.vue";

export default {
  components: { mymap: Map, Drivers },
  data() {
    return {
      windowHeight: window.innerHeight - 130,
      driversKey: Date.now(),
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 130;
    });

    map.clearAll();
  },
  methods: {
    refresh() {
      this.driversKey = Date.now();
    },
  },
};
</script>

<style scoped>
.orders-index {
  overflow-y: scroll;
}
</style>