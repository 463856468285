import * as providerService from '@/services/providerService'
const initialState = {
    provider: null,
    status: null
}

export const provider = {
    namespaced: true,
    state: initialState,
    actions: {
        getProvider({ dispatch, commit }) {
            commit('providerRequest');
            providerService.getProvider()
                .then(resp => {
                    var provider = resp.data;
                    if (provider) {
                        commit('providerSuccess', provider);
                    }
                    else {
                        commit('providerFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('providerFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        providerRequest(state) {
            state.status = { loading: true };
            state.provider = null;
        },
        providerSuccess(state, provider) {
            state.status = { loaded: true };
            state.provider = provider;
        },
        providerFailure(state) {
            state.status = null;
            state.provider = null;
        }
    },
    getters: {
        gcid: state => {
            return state.provider.geoContents[0].id;
        }
    }
}