import myAxios from '@/services/myAxios'

const initialState = {
    settings: {
        mapLat: 59.942286,
        mapLon: 30.305557,
        currency: "",
    },
    saveSettingsStatus: ""
}

export const accountSettings = {
    namespaced: true,
    state: initialState,
    actions: {
        getSettings({
            dispatch,
            commit
        }) {
            myAxios.get("/api/account/settings").then((resp) => {
                if (resp.data && resp.data.mapLat) {
                    commit('setSettings', resp.data);
                }
            })
        },
        saveSettings({
                dispatch,
                commit
            },
            settings
        ) {
            myAxios.post("/api/account/settings", settings)
                .then(() => {
                    dispatch('getSettings')
                    commit('setSaveSettingsStatus', "Settings saved!")
                    utils.sleep().then(() => {
                        commit('setSaveSettingsStatus', "")
                    })
                })
                .catch(err => {
                    commit('setSaveSettingsStatus', err.message)
                })
        },
    },
    mutations: {
        setSettings(state, settings) {
            state.settings = settings

        },
        setSaveSettingsStatus(state, payload) {
            state.saveSettingsStatus = payload
        },

    },
    getters: {
        latLon: state => {
            if (state.settings && state.settings.mapLat) {
                return [state.settings.mapLat, state.settings.mapLon];
            }
            return null;
        }
    }
}

const utils = {
    sleep() {
        return new Promise((resolve) => {
            setTimeout(resolve, 2500);
        });
    },
}