<template>
  <div class="row" v-if="auth.user.id">
    <div class="col-3">
      <h5>Тарифные зоны</h5>
      <div>
        <button class="btn btn-outline-success btn-sm" @click="createZone">
          Новая зона
        </button>
        <zone
          v-for="zone in zones"
          :key="zone.id"
          :zone="zone"
          @onZoneClick="onZoneClick"
          @saveZone="onSaveZone"
          @deleteZone="onDeleteZone"
          :isActive="zone.id === activeZoneId"
        ></zone>
      </div>
    </div>
    <div class="col-9">
      <mymap />
    </div>
  </div>
</template>

<script>
import Map from "./Map.vue";
import myAxios from "@/services/myAxios";
import Zone from "./Zone";
import map from "@/map2.js";
import { eventBus } from "@/main";

export default {
  components: {
    mymap: Map,
    Zone,
  },
  data() {
    return {
      windowHeight: window.innerHeight - 140,
      zones: [],
      activeZoneId: 0,
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 140;
    });

    this.loadZones(true);

    eventBus.$on("zoneUpdated", this.zoneGeometryUpdated);
    eventBus.$on("zoneCreated", this.zoneGeometryCreated);
  },
  methods: {
    loadZones(shouldDraw) {
      if (this.auth.user.id > 0) {
        myAxios
          .get("http://pkapi.bgeo.fi/taxi_service_2/taxi/zone?userId=" + this.auth.user.id)
          .then((resp) => {
            this.zones = resp.data;
            if (shouldDraw) {
              this.drawZones(this.zones);
            }
          });
      }
    },
    drawZone(zone) {
      var zoneJson = {
        type: "Feature",
        properties: {
          name: zone.name,
          id: zone.id,
        },
        geometry: zone.border,
      };
      map.drawZone(zoneJson);
    },
    drawZones(zones) {
      map.clearZonesLayer();

      zones.forEach((zone) => {
        this.drawZone(zone);
      });
    },
    onZoneClick(zoneId) {
      this.activeZoneId = zoneId;

      var zonesExceptActive = this.zones.filter((o) => o.id !== zoneId);

      this.drawZones(zonesExceptActive);

      var activeZone = this.zones.find((o) => o.id === zoneId);

      map.makeZoneEditable(activeZone.border);
    },
    zoneGeometryUpdated(zonePolygon) {
      var border = zonePolygon.features[0].geometry;

      var activeZone = this.zones.find((o) => o.id === this.activeZoneId);

      activeZone.border = border;
    },
    zoneGeometryCreated(zonePolygon) {
      var border = zonePolygon.geometry;

      var activeZone = this.zones.find((o) => o.id === this.activeZoneId);

      activeZone.border = border;
    },
    onSaveZone(zone) {
      var activeZone = this.zones.find((o) => o.id === zone.id);

      var comboZone = { ...zone, border: activeZone.border, userId: this.auth.user.id };

      if (zone.id > 0) {
        myAxios
          .put(
            "http://pkapi.bgeo.fi/taxi_service_2/taxi/zone/" + activeZone.id,
            comboZone
          )
          .then(() => this.loadZones(true));
      } else {
        myAxios
          .post("http://pkapi.bgeo.fi/taxi_service_2/taxi/zone/", comboZone)
          .then(() => this.loadZones(true));
      }
    },
    createZone() {
      var newZone = {
        id: 0,
        name: "",
        priceModifier: 0,
        pickupKmRate: 0,
        useTariffIncludedPacket: false,
        useTariffPickupKmRate: false,
        border: null
      };

      var newArr = [newZone, ...this.zones];

      this.zones = newArr;

      this.activeZoneId = 0;
    },
    onDeleteZone(zoneId) {
      if (zoneId > 0) {
        myAxios
          .delete("http://pkapi.bgeo.fi/taxi_service_2/taxi/zone/" + zoneId)
          .then(() => this.loadZones(true));
      } else {
        this.loadZones(true);
      }
    },
  },
};
</script>