<template>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Release Date</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="ntm in ntms" :key="ntm.id">
        <td>
          <a
            :href="'https://service.probki.net/mkart/geocontents/mkart_gc/ntm/' + ntm.id + '.pdf'"
          ><my-date :date="ntm.addDate"></my-date></a>
        </td>
        <td>{{ntm.description}}</td>
        <td>
          <button class="btn btn-sm btn-outline-danger" @click="removeNtm(ntm.id)">remove</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: { ntms: null },
  methods: {
    removeNtm(id) {
      this.$emit("removeNtm", id);
    },
  },
};
</script>