<template>
  <div class="row">
    <div class="col-md-4">
      <h2>Registartion</h2>
      <!-- <div v-if="registration && registration.failure" class="alert alert-danger">{{registration.message}}</div>
      <form @submit.prevent="handleSubmit" class="form">
        <div class="form-group">
          <label for="name">Title</label>
          <input class="form-control" type="name" name="name" v-model="name" />
        </div>
        <div class="form-group">
          <label for="username">Email</label>
          <input class="form-control" type="email" name="email" v-model="email" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input class="form-control" type="password" name="password" v-model="password" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Submit</button>
        </div>
      </form> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: ""
    };
  },
  computed: {
    registration() {
      return this.$store.state.auth.registration;
    }
  },
  methods: {
    handleSubmit() {
      if (this.email && this.name) {
        this.$store.dispatch("auth/register", {
          email: this.email,
          name: this.name,
          userType: this.$route.params.regtype
        });
      }
    }
  }
};
</script>