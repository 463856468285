<template>
  <div>
    <div class="row">
      <div class="col-4 vpindex" :style="{ height: windowHeight + 'px' }">
        <div
          style="
            border: solid 1px #ced4da;
            border-radius: 5px;
            padding: 15px;
            margin: 5px;
          "
        >
          <div class="vp-title">
            <button
              class="btn btn-outline-primary btn-sm"
              @click="downloadVP"
              :disabled="!isComplete"
            >
              Save
            </button>
            <hr />
            <div class="form-group">
              <label for="">Voyage Plan Name</label
              ><input class="form-control" type="text" v-model="vp.name" />
            </div>
            <div class="form-group">
              <label for="">Organization</label
              ><input
                class="form-control"
                type="text"
                v-model="vp.organization"
              />
            </div>
            <div class="form-group">
              <label for="">Driver</label
              ><input class="form-control" type="text" v-model="vp.driver" />
            </div>
            <div class="row">
              <div class="form-group col">
                <label for="">Car</label
                ><input class="form-control" type="text" v-model="vp.car" />
              </div>
              <div class="form-group col">
                <label for="">Car Type</label
                ><input class="form-control" type="text" v-model="vp.carType" />
              </div>
            </div>
            <div class="form-group">
              <label for="">Task Type</label
              ><input class="form-control" type="text" v-model="vp.taskType" />
            </div>
            <div class="form-group">
              <label for="">Comment</label
              ><input class="form-control" type="text" v-model="vp.comment" />
            </div>
          </div>

          <item
            v-for="(item, index) in vp.items"
            :key="index"
            :item="item"
            @deleteItem="deleteItem"
          />

          <new-item @addItem="addItem"></new-item>
        </div>
      </div>
      <div class="col-8">
        <mymap></mymap>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "./Map.vue";
import NewItem from "./NewItem.vue";
import Item from "./Item.vue";
import map from "@/map2";
import axios from "axios";
import moment from "moment"

export default {
  components: { mymap: Map, NewItem, Item },
  data() {
    return {
      vp: {
        id: 0,
        name: "",
        organization: "",
        driver: "",
        car: "",
        carType: "",
        taskType: "",
        comment: "",
        items: [],
      },
      windowHeight: window.innerHeight - 70,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 70;
    });
    map.removeToolTips();
    map.removeRoute();
  },
  computed: {
    isComplete() {
      return this.vp.items.length > 0 && this.vp.name;
    },
  },
  methods: {
    addItem(item) {
      this.vp.items.push(item);
      this.drawRoute();
    },
    deleteItem(id) {
      var index = this.vp.items.map((o) => o.id).indexOf(id);
      if (index >= 0) {
        var itm = this.vp.items.find((x) => x.id === id);
        if (itm && itm.commands.length > 0) {
          itm.commands.forEach((cmd) => {
            map.vpEditorDeletePoint(cmd.id);
          });
        }
        this.vp.items.splice(index, 1);
      }
      this.drawRoute();
    },
    downloadVP() {
      this.vp.id = Date.now();
      this.vp.issueDate = moment().format()
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," +
          encodeURIComponent(
            JSON.stringify(this.vp, function (key, value) {
              return value == null ? "" : value;
            })
          )
      );
      element.setAttribute("download", this.vp.name + "_" +moment(this.vp.issueDate).format("YYYY-MM-DD_HH-mm") + ".json");
      element.style.display = "none";
      element.click();
      URL.revokeObjectURL(element.href);
    },
    drawRoute() {
      map.removeRoute();

      var goTos = [];
      this.vp.items.forEach((itm) => {
        itm.commands.forEach((cmd) => {
          if (cmd.type === "goTo") {
            goTos.push({
              latitude: Number(cmd.lat),
              longitude: Number(cmd.lon),
            });
          }
        });
      });

      if (goTos.length > 1) {
        var routeCalcParams = {
          type: "OptimizeTime",
          allowYards: true,
          allowSideWays: false,
          allowGroundRoads: true,
          allowPaidRoads: true,
          allowRailwayCrossing: true,
          useJams: false,
          useLimitsUpd: true,
          useForecast: false,
        };

        var routes = [];
        routes.push({
          Points: goTos,
        });

        var request = {
          accessKey: "WWWB2BPROBKINET",
          getPoints: true,
          parameters: routeCalcParams,
          routes: routes,
          carParameters: null,
        };

        axios
          .post("https://api.probki.net/api/route", request, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            map.drawRoute(resp.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.vpindex {
  overflow-y: scroll;
}
</style>