import myAxios from './myAxios';

export {
    login,
    logout,
    register,
    refreshUser
};

function login(username, password) {
    return myAxios.post('/auth/login', { username, password });
}

function logout(){
    localStorage.removeItem('user');
}

function refreshUser(){
    return myAxios.post('/auth/refresh');
}

function register(email, userType, repassword) {
    return myAxios.post('/auth/register', { email, userType, repassword });
}