<template>
        <tr>
          <td>
            {{curMap.name}}
          </td>
          <td>{{ curMap.filename }}</td>
          <td>
            <select class="form-control" v-model="curMap.state">
              <option
                v-for="state in mapStates"
                :key="state.key"
                :value="state"
              >
                {{
                  Object.keys(mapStates).find((key) => mapStates[key] === state)
                }}
              </option>
            </select>
          </td>
          <td>
            <button
              v-if="curMap.state === 2"
              class="btn btn-sm btn-outline-primary ml-3"
              @click="publishMap"
            >
              publish
            </button>
          </td>
          
          <td  style="width:120px">
            <input
              type="text"
              class="form-control"
              v-model="curMap.uniqueId"
              placeholder="unique id"
            />
          </td>
          <td style="width:120px">
            <input
              type="text"
              class="form-control"
              v-model="curMap.substituteMapUniqueId"
              placeholder="subst. unique id"
            />
          </td>
          <td>
            <div class="custom-file" style="width:80px">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                @change="uploadFiles"
              />
              <label class="custom-file-label" for="customFile" data-browse="add file">map file</label>
            </div>
          </td>
          <td>
            <button type="submit" class="btn btn-primary btn-sm">Update</button>
          </td>
          <td>
            <button
              class="btn btn-warning btn-sm"
              @click.prevent="
              UpdateMap;
                curMap = {};
              "
            >
              Cancel
            </button>
          </td>
          <td>
            <button class="btn btn-danger btn-sm" @click="deleteMap">
              Delete
            </button>
          </td>
        </tr>
</template>

<script>
import myAxios from "@/services/myAxios";
import MapFile from "@/components/geocontent/MapFile.vue";

export default {
  props: {
    map: {},
  },
  data() {
    return {
      curMap: this.map,
      mapStates: Object.freeze({
        forming: 0,
        processing: 1,
        "ready for publishing": 2,
        published: 3,
      }),
    };
  },
  methods: {
    UpdateMap() {
      var map = this.curMap;
      map = JSON.stringify(map);
      myAxios
        .put("/maps/UpdateMap", map, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.amICollapsed = true;
          this.$store.dispatch("mapsCollections/getMapsCollection");
        });
    },
    publishMap() {
      myAxios.get("/maps/PublishMap?id=" + this.curMap.id).then(() => {
        this.$store.dispatch("mapsCollections/getMapsCollection");
      });
    },
    deleteMap() {
      myAxios.delete("/maps/DeleteMap?mapid=" + this.curMap.id).then(() => {
        this.$store.dispatch("mapsCollections/getMapsCollection");
      });
    },
    uploadFiles(event) {
      var formData = new FormData();
      event.target.files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("mapid", this.curMap.id);
      this.uploading = true;
      myAxios
        .post("/maps/AddFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.dispatch("mapsCollections/getMapsCollection");
        });
    },
  },
};
</script>