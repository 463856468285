<template>
  <div class="row">
    <div class="col">
      <h5>Find position</h5>
      <div class="form-group my-4">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="sendRequest"
        >
          Request position
        </button>
      </div>
      <hr />
      <div>
        <h6>Request results</h6>
        <table class="exchlog table table-sm" v-if="exchanges">
          <tr>
            <td>Date</td>
            <td></td>
          </tr>
          <tr v-for="exch in exchanges" :key="exch.id">
            <td>{{ fromOADate(exch.timestamp) }}</td>
            <td>
              <car-info-item
                :carinfo="exch"
              ></car-info-item>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-8 mapdiv">
      <mymap></mymap>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import CarInfoItem from "./carInfoItem.vue";
import Map from "@/views/provider/geocontent/catalog/route-plan/Map.vue";
import map from "@/map2.js";

export default {
  components: {
    CarInfoItem,
    mymap: Map,
  },
  props: {
    deviceid: null,
  },
  data() {
    return {
      exchanges: null,
      file: null,
    };
  },
  created() {
    this.getExchanges();
  },
  methods: {
    processJson(jsonstr) {
      if (jsonstr === "no data") return null;
      return JSON.parse(jsonstr);
    },
    getExchanges() {
      myAxios
        .get("/api/teleapi/getexchanges", {
          params: { deviceId: this.deviceid, action: "carinfo" },
        })
        .then((resp) => {
          this.exchanges = resp.data;
          var last = this.exchanges[0];
          this.showOnMap(last.lat, last.lon);
        })
    },
    sendRequest() {
      if (this.deviceid) {
        myAxios
          .get("/api/teleapi/CarInfoRequest", {
            params: { deviceId: this.deviceid },
          })
          .then(() => {
            this.getExchanges();
          });
      }
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    }
  },
};
</script>

<style scoped>
.exchlog td {
  font-size: 10px;
}

.mapdiv {
  height: 500px;
}
</style>