import * as authService from '@/services/authService'
import router from '@/router/'

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null, registration: {} };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit, rootGetters }, { username, password }) {
            commit('loginRequest', { username });
            authService.login(username, password)
                .then(resp => {
                    var user = resp.data;
                    if (user.token) {
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    commit('loginSuccess', user);
                    //var firstRole = user.roles[0].role;

                    var _locale = rootGetters.locale.code !== 'en' ? rootGetters.locale.code : null;
                    router.push({ name: 'user', params: { locale: _locale } });
                })
                .catch(error => {
                    commit('clearStatus');
                    commit('loginFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        refreshUser({ dispatch, commit, rootGetters }, goto) {
            //commit('loginRequest', { username });
            authService.refreshUser()
                .then(resp => {
                    var user = resp.data;
                    if (user.token) {
                        localStorage.removeItem('user');
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    commit('loginSuccess', user);
                    //var firstRole = user.roles[0].role;
                    var _locale = rootGetters.locale.code !== 'en' ? rootGetters.locale.code : null;
                    router.push({ name: goto, params: { locale: _locale } });
                })
                .catch(error => {
                    commit('clearStatus');
                    commit('loginFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        logout({ commit, rootGetters }) {
            authService.logout();
            commit('logout');
            var _locale = rootGetters.locale.code !== 'en' ? rootGetters.locale.code : null;
            router.push({ name: 'home', params: { locale: _locale } });
        },
        register({ dispatch, commit }, { email, userType, repassword }) {
            authService.register(email, userType, repassword)
                .then(resp => {
                    commit('registrationSuccess', email);
                    //router.push("/login");
                })
                .catch(error => {
                    var err = error.response.data.message || error.message
                    commit('registrationFailure', err)
                })
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.registration = {};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },
        registrationSuccess(state, email) {
            state.registration = { success: true, email };
        },
        registrationFailure(state, message) {
            state.registration = { failure: true, message };
        },
        clearStatus(state) {
            state.status = {};
            state.registration = {};
        }
    }
}