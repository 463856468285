<template>
  <div>
    <button class="btn btn-outline-primary btn-sm m-2" v-if="isHidden" @click="isHidden = false">Add Task</button>
    <div
      v-else
      style="
        border: solid 1px #ced4da;
        border-radius: 5px;
        padding: 15px;
        margin: 5px;
      "
    >
    <h6>Task</h6>
      <div class="form-group">
        <label>Task Name</label>
        <input class="form-control" type="text" v-model="item.name" />
      </div>
      <div>
        <command
          @deleteCommand="deleteCommand"
          v-for="(cmd, index) in item.commands"
          :key="index"
          :cmd="cmd"
        />
      </div>

      <new-command @addCommand="addCommand"></new-command>
      <button class="btn btn-outline-primary btn-sm mt-2" :disabled="!isComplete" @click="save">Save Task</button>
    </div>
  </div>
</template>

<script>
import NewCommand from "./NewCommand.vue";
import Command from "./Command.vue";
import map from "@/map2.js";

export default {
  components: { NewCommand, Command },
  data() {
    return { item: { name: null, commands: [] }, isHidden: true };
  },
  computed: {
    isComplete() {
      return this.item.commands.length > 0;
    },
  },
  methods: {
    addCommand(cmd) {
      cmd.id = Date.now();
      this.item.commands.push(cmd);
      map.vpEditorAddPoint({ lat: cmd.lat, lng: cmd.lon }, cmd.id, cmd.action);
    },
    deleteCommand(cmd) {
      var index = this.item.commands.indexOf(cmd);
      if (index >= 0) {
        this.item.commands.splice(index, 1);
      }
      map.vpEditorDeletePoint(cmd.id);
    },
    save() {
      var newItem = { ...this.item };
      newItem.commands = [...this.item.commands];
      newItem.id = Date.now();
      this.$emit("addItem", newItem);
      this.item = { name: null, commands: [] };
      this.isHidden = true;
    },
  },
};
</script>