<template>
  <div id="mymap" ref="mm" :style="{ height: windowHeight + 'px' }"></div>
</template>

<script>
import map from "@/map2.js";
import "leaflet/dist/leaflet.css";

export default {
  data() {
    return {
      windowHeight: window.innerHeight - 90,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 90;
    });

    map.init(this.$refs.mm, this.taskId);
  },
};
</script>

<style scoped>
#mymap {
  border: solid 1px dimgray;
}
</style>