<template>
  <div class="cmd-item" @click="actionclick()">
    <h6>{{ cmd.action }}</h6>
    <h6>
      <span v-if="cmd.type === 'goTo'">Едем к {{ cmd.address }}</span>
      <span v-if="cmd.type === 'execute'">Выполняем {{ cmd.action }}</span>
    </h6>
    <div>{{ cmd.comment }}</div>
    <div>
      <span v-bind:class="cmdStateBadge">{{ cmdStateText }}</span>
    </div>
    <p>
      <strong>{{ timeStateText }}</strong>
    </p>
    <table>
      <tr>
        <td>Расчетное время</td>
        <td>
          <strong>{{ mmoment(cmd.estimatedTime) }}</strong>
        </td>
      </tr>
      <tr>
        <td>Запланировано</td>
        <td>
          <strong>
            <span v-if="cmd.type === 'goTo'"
              >с {{ mmoment(cmd.plannedTimeStart) }}</span
            >
            <span v-else-if="cmd.type === 'execute'">{{
              dateTimeInterval(cmd.plannedTimeStart, cmd.plannedTimeDuration)
            }}</span>
          </strong>
        </td>
      </tr>
      <tr v-if="cmd.plannedTimeDuration">
        <td>на выполнение</td>
        <td>
          <strong>{{ cmd.plannedTimeDuration }} мин</strong>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import map from "@/map2.js";

export default {
  props: {
    cmd: {},
  },
  computed: {
    timeStateText() {
      switch (this.cmd.time_state) {
        case "in_time":
          return "вовремя";
        case "delayed":
          return "с опозданием (" + this.calculateDelay + ")";
        default:
          return this.cmd.time_state;
      }
    },
    cmdStateText() {
      switch (this.cmd.state) {
        case "done":
          return "выполнено";
        case "started":
          return "выполняется";
        case "planned":
          return "";
        case "suspended":
          var suspDetails = "";
          if (this.cmd.suspendedHistory && this.cmd.suspendedHistory[0])
            suspDetails =
              this.cmd.suspendedHistory[0].duration +
              " мин. от " +
              this.mmoment(this.cmd.suspendedHistory[0].startDate);
          return "задержка " + suspDetails;
        case "cancelled":
          return "отменено";
        default:
          return this.cmd.state;
      }
    },
    cmdStateBadge: function () {
      switch (this.cmd.state) {
        case "done":
          return { badge: true, "badge-success": true };
        case "started":
          return { badge: true, "badge-primary": true };
        case "planned":
          return { badge: true, "badge-info": true };
        case "suspended":
          return { badge: true, "badge-warning": true };
        default:
          return { badge: true, "badge-secondary": true };
      }
    },
    calculateDelay() {
      if (this.cmd.plannedTimeStart && this.cmd.estimatedTime) {
        var eta = this.cmd.estimatedTime;
        var planned = this.cmd.plannedTimeStart;

        var diff = new Date(eta) - new Date(planned);
        var mins = moment.duration(Math.abs(diff)).asMinutes().toFixed(0);
        var hoursMins = Math.floor(mins / 60) + " ч  " + (mins % 60) + "м";
        return hoursMins;
      }
      return null;
    },
  },
  methods: {
    mmoment(date) {
      if (date) return moment(date).format("HH:mm DD.MM.YY");
      return "";
    },
    dateTimeInterval(date, duration) {
      return (
        moment(date).format("HH:mm") +
        " - " +
        moment(date).add(duration, "minutes").format("HH:mm DD.MM.YY")
      );
    },
    actionclick() {
      if (this.cmd.lat) {
        map.setView({ lat: this.cmd.lat, lon: this.cmd.lon });
      }
    },
  },
};
</script>

<style scoped>
.cmdType {
  color: cornflowerblue;
  font-weight: 700;
}

.cmd-item {
  font-size: 15px;
  border: solid 1px rgb(175, 175, 175);
  padding: 7px;
}
</style>