import router from '@/router/'

const initialState = {
    goods: [
        { id: 1, name: "Volga", price: 500, added: false },
        { id: 2, name: "Neva", price: 500, added: false },
        { id: 3, name: "Ladoga", price: 500, added: false },
        { id: 4, name: "Enisey", price: 500, added: false }
    ],
    cart: null,
    status: null
}

export const shop = {
    namespaced: true,
    state: initialState,
    actions: {
        addToCart({ dispatch, commit }, item) {
            commit('addToCart', item);
        },
        removeFromCart({ dispatch, commit }, item) {
            commit('removeFromCart', item);
        },
        clearCart({ dispatch, commit }) {
            commit('clearCart');
            router.push({ name: "user"});
        }
    },
    mutations: {
        addToCart(state, item) {
            var itm = state.goods.filter(o => o.id == item.id)[0];
            itm.added = true;
            if (state.cart) {
                state.cart.push(item);
            }
            else {
                var cart = [];
                cart.push(item)
                state.cart = cart;
            }
        },
        removeFromCart(state, item) {
            var newcart = [];
            state.cart.forEach(itm => {
                if (itm.id !== item.id) {
                    newcart.push(itm)
                }
            });
            var itm = state.goods.filter(o => o.id == item.id)[0];
            itm.added = false;
            state.cart = newcart;
        },
        clearCart(state) {
            state.goods.forEach(itm => { itm.added = false });
            state.cart = null;

        }
    },
    getters: {
        total: state => {
            if (state.cart) {
                var total = 0;
                state.cart.forEach(item => {
                    total += item.price;
                });
                return total;
            }
            return 0;
        }
    }
}