<template>
  <div class="mb-2 px-4 py-1 row" id="add_permit" v-if="geoContent.products">
    <div class="col-md-6">
      <form class="p-3 form" v-on:submit.prevent>
        <div class="form-group">
          <label class="form-label">Email</label>
          <input type="email" class="form-control" v-model="email" id="email" placeholder="Email" />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">{{ $t('publisher.gc.lics.add.devnum') }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="devnum"
                id="exp"
                placeholder="0"
                style="width:90px"
              />
              <small class="form-text text-muted">{{ $t('publisher.gc.lics.add.unlim') }}</small>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">{{ $t('publisher.gc.lics.add.lictype') }}</label>
              <select class="custom-select" v-model="prodid">
                <option>{{ $t('publisher.gc.lics.add.select') }}</option>
                <option v-for="prod in products" :key="prod.id" :value="prod.id">
                  {{ $t('publisher.gc.lics.add.' + prod.licType) }}
                  <span v-if="prod.licType === 'com'">({{prod.price}})</span>
                </option>
              </select>
            </div>
          </div> -->
        </div>
        <button
          type="submit"
          @click="addPermit"
          class="btn btn-primary"
        >{{ $t('publisher.gc.lics.add.issue') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gcid: null
  },
  data() {
    return {
      email: "",
      exp: "12",
      devnum: "0",
      prodid: 0
    };
  },
  computed: {
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    },
  },
  methods: {
    addPermit() {
      if (this.email && this.gcid) {
        this.$store.dispatch("geoContent/addPermit", {
          email: this.email,
          gcid: this.gcid,
          exp: this.exp,
          devnum: this.devnum
        });
      }
    }
  }
};
</script>
<style scoped>
#add_permit {
  border: solid 1px #e5e5e5;
}
</style>