<template>
  <div>
    <h4>{{ $t('publisher.reg.title') }}</h4>
    <form class="form" @submit.prevent="handleSubmit">
      <div class="alert" v-if="error">{{ $t('publisher.reg.failed') }}</div>
      <div class="form-group">
        <label>{{ $t('publisher.reg.content') }}</label>
        <input type="text" class="form-control" v-model="contentName" placeholder="Title" />
      </div>
      <div class="form-group">
        <label>{{ $t('publisher.reg.publisher') }}</label>
        <input type="text" class="form-control" v-model="providerName" placeholder="Title" />
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="isConfirmed" id="defaultCheck1" />
        <label class="form-check-label">
          {{ $t('publisher.reg.confirm') }}
        </label>
      </div>
      <div class="form-group my-3">
        <button
          type="submit"
          class="btn btn-primary"
          v-bind:class="{ disabled: !isConfirmed }"
        >{{ $t('publisher.reg.create') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      contentName: null,
      providerName: null,
      isConfirmed: false,
      error: null
    };
  },
  methods: {
    handleSubmit() {
      if (this.contentName && this.providerName && this.isConfirmed) {
        myAxios
          .get("/api/geocontent/create2", {
            params: {
              gcTitle: this.contentName,
              providerTitle: this.providerName
            }
          })
          .then(resp => {
            if (resp.data) {
              this.$store.dispatch("auth/refreshUser", "provider");
            } else {
              this.error = true;
            }
          })
          .catch(err => {});
      }
    }
  }
};
</script>