<template>
  <div>
    <ul class="list-unstyled">
      <li v-for="order in orders" :key="order.id">
        <order :order="order" v-on:orderChanged="loadOrders"></order>
      </li>
    </ul>
  </div>
</template>

<script>
import Order from "./Order.vue";
import myAxios from "@/services/myAxios";
import map from "@/map2.js";

export default {
  components: { Order },
  data() {
    return {
      orders: [],
    };
  },
  mounted() {
    map.clearAll();
    this.loadOrders();
  },
  methods: {
    loadOrders() {
      myAxios.get("/api/taxiorders/list").then((resp) => {
        this.orders = resp.data;
      });
    },
  },
};
</script>