import axios from 'axios';
import { authHeader } from '@/helpers/authHeader'

const myAxios = axios.create({ baseURL: 'https://' + process.env.VUE_APP_API_BASE_URL });

myAxios.interceptors.request.use(
    function (config) {
        config.headers.Authorization = '';
        var hdr = authHeader();
        if (hdr.Authorization) {
            config.headers.Authorization = hdr.Authorization;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

myAxios.interceptors.response.use(
    function (response) {
        if(response.headers["newone"])
        {
            var user = JSON.parse(localStorage.getItem("user"));
            user.token = response.headers["newone"];
            localStorage.setItem('user', JSON.stringify(user));
        }
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('user');
            location.reload(true);
        }
        return Promise.reject(error);
    }
);

export default myAxios;