<template>
  <div>
    <h4>{{ $t('distributor.reg.title') }}</h4>
    <form class="form" @submit.prevent="handleSubmit">
      <div class="alert" v-if="error">
        <h4>{{ $t('distributor.reg.failed') }}</h4>
      </div>
      <div class="form-group">
        <label>{{ $t('distributor.reg.distributor') }}</label>
        <input type="text" class="form-control" v-model="distirbutorName" placeholder="Title" />
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="isConfirmed" id="defaultCheck1" />
        <label class="form-check-label">{{ $t('distributor.reg.confirm') }}</label>
      </div>
      <div class="form-group my-3">
        <button
          type="submit"
          class="btn btn-primary"
          v-bind:class="{ disabled: !isConfirmed }"
        >{{ $t('distributor.reg.create') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      distirbutorName: null,
      isConfirmed: false,
      error: null
    };
  },
  methods: {
    handleSubmit() {
      if (this.distirbutorName && this.isConfirmed) {
        myAxios
          .get("/api/distributors/create", {
            params: {
              title: this.distirbutorName
            }
          })
          .then(resp => {
            if (resp.data) {
              this.$store.dispatch("auth/refreshUser", "distributor");
            } else {
              this.error = true;
            }
          })
          .catch(err => {});
      }
    }
  }
};
</script>