<template>
  <div>
    <p v-if="status.processing">{{ $t('publisher.gc.cat.enc.processing') }}</p>
    <router-view name="encView"></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    }
  }
};
</script>