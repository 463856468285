import * as mapsService from '@/services/mapsService'
const initialState = {
    mapsCollections: null,
    status: null,
}

export const mapsCollections = {
    namespaced: true,
    state: initialState,
    actions: {
        getMapsCollection({ dispatch, commit, rootGetters }) {
            commit('mapsCollectionRequest');
            var gcid = rootGetters['provider/gcid']
            mapsService.getMapsCollections(gcid)
                .then(resp => {
                    var mapsCollection = resp.data;
                    if (mapsCollection) {
                        commit('mapsCollectionSuccess', mapsCollection);
                    }
                    else {
                        commit('mapsCollectionFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('mapsCollectionFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        mapsCollectionRequest(state) {
            state.status = { loading: true };
            state.mapsCollections = null;
        },
        mapsCollectionSuccess(state, mapsCollection) {
            state.status = { loaded: true };
            state.mapsCollections = mapsCollection;
        },
        mapsCollectionFailure(state) {
            state.status = null;
            state.mapsCollections = null;
        },
    }
}