import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import i18n from '../localization/i18n';

import { SUPPORTED_LOCALES } from '../localization/locales'
import store from '../store'

import allroutes from './routes'
import RootComponent from '@/components/RootComponent.vue'

Vue.use(VueRouter)



// Creates regex (en|fr)
function getLocaleRegex() {
  let reg = '';
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
}

// Returns locale configuration
function getLocale(locale = 'en') {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: `/:locale${getLocaleRegex()}?`,
    component: RootComponent,
    // beforeEnter(to, from, next) {
    //   //const locale = getLocale(to.params.locale);
    //   //store.dispatch('setLocale', locale);
    //   axios.get(locale.translations).then(res => {
    //     i18n.setLocaleMessage(locale.code, res.data || {});
    //   }).catch((err) => {
    //   }).finally(() => {
    //     i18n.locale = locale.code;
    //     next();
    //   });
    // },
    children: allroutes
  }]
})

function setLocaleFromPath(to) {
  var pathArray = to.fullPath.split("/");
  var loc = "en";

  if (pathArray.length > 1 && pathArray[1].length === 2 && SUPPORTED_LOCALES.find(loc => loc.code === pathArray[1])) {
    loc = pathArray[1];
    to.params.locale = loc;
  }

  const locale = getLocale(loc);
  store.dispatch('setLocale', locale);

  axios.get(locale.translations).then(res => {
    i18n.setLocaleMessage(locale.code, res.data || {});
  }).finally(() => {
    i18n.locale = locale.code;
  });
}

router.beforeEach((to, from, next) => {
  setLocaleFromPath(to);

  if (to.matched.some(record => record.meta.role)) {
    if (localStorage.getItem('user') == null) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath }
      })
    }
    else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user.roles.some(r => r.role === to.meta.role)) {
        next()
      }
      else {
        //var pathStr = "/" + to.meta.role + "/registration"
        next({ name: to.meta.role + 'Registration' });
      }
    }
  }
  else {
    next()
  }
})

export default router
