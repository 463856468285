<template>
  <div>
    <div class="row">
      <div
        class="col-md-4"
      >
        <div class="modes">
          <span
            v-bind:class="modeBadgeStyle('orders')"
            @click="setDisplayMode('orders')"
            >Orders view</span
          >
          <span
            v-bind:class="modeBadgeStyle('drivers')"
            @click="setDisplayMode('drivers')"
            >Drivers view</span
          >
        </div>
        <div :style="{ height: windowHeight + 'px' }" class="orders-index">
          <button
            class="btn btn-outline-primary btn-sm"
            @click="createOrder"
            v-if="!createOrderMode"
          >
            Create order
          </button>
          <button
            class="btn btn-outline-secondary btn-sm ml-2"
            @click="refresh"
          >
            Refresh
          </button>
          <div>
            <new-order
              v-if="createOrderMode"
              v-on:orderCreated="onOrderCreated"
            ></new-order>
            <orders :key="ordersKey" v-else-if="displayMode === 'orders'" />
            <drivers :key="driversKey" v-else-if="displayMode === 'drivers'" />
          </div>
        </div>
      </div>
      <div class="col-md-8"><mymap></mymap></div>
    </div>
  </div>
</template>

<script>
import Map from "./Map.vue";
import NewOrder from "./NewOrder.vue";
import map from "@/map2.js";
import Orders from "./Orders.vue";
import Drivers from "./Drivers.vue";

export default {
  components: { mymap: Map, NewOrder, Orders, Drivers },
  data() {
    return {
      displayMode: "orders",
      createOrderMode: false,
      windowHeight: window.innerHeight - 110,
      ordersKey: Date.now(),
      driversKey: Date.now(),
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 110;
    });

    map.clearAll();
  },
  methods: {
    refresh() {
      switch (this.displayMode) {
        case "orders":
          this.ordersKey = Date.now();
          break;
        case "drivers":
          this.driversKey = Date.now();
          break;
      }
    },
    setDisplayMode(mode) {
      this.displayMode = mode;
      this.refresh;
    },
    createOrder() {
      map.clearAll();
      this.createOrderMode = true;
    },
    modeBadgeStyle(badgeMode) {
      if (badgeMode === this.displayMode) {
        return { badge: true, "badge-primary": true };
      }
      return { badge: true, "badge-secondary": true };
    },
    onOrderCreated() {
      map.clearAll();
      this.createOrderMode = false;
      this.refresh();
    },
  },
};
</script>

<style scoped>
.orders-index {
  overflow-y: scroll;
}

.modes {
  text-align: right;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.modes > span {
  margin-left: 5px;
  cursor: pointer;
}
</style>