<template>
  <div>
    <h5>Get Voyage Plans</h5>
    <div class="row">
      <div class="col-md-4 vpbrief" :style="breiefRowHeight">
        <button
          class="btn btn-sm btn-outline-primary my-3"
          @click="getExchanges"
        >
          Refresh
        </button>
        <table class="table table-sm" v-if="exchanges">
          <tr></tr>
          <tr v-for="exch in exchanges" :key="exch.id">
            <td>
              <div>{{ exch.name ? exch.name : exch.filename }} от {{mdate(exch.issueDate)}} - {{ exch.status ? exch.status : 'delivered' }}</div>
              <div>
                <!-- <span class="filelink" @click="showVoyagePlan(exch.filename)"
                  >show</span
                > -->
                <router-link :to="{name: 'voyageplan', params: { deviceId: deviceid, filename: exch.filename }}" :aclass="'active'">show</router-link>
                |
                <span class="filelink" @click="getVoyagePlan(exch.filename)">download</span>
              </div>
              <brief :vp="vp"></brief>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-8 mapdiv">
        <mymap></mymap>
      </div>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import brief from "./voyage-plan/brief.vue";
import Map from "@/views/provider/geocontent/catalog/route-plan/Map.vue";
import moment from "moment";

export default {
  components: {
    mymap: Map,
    brief,
  },
  props: {
    deviceid: null,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      exchanges: null,
      file: null,
      vp: {},
    };
  },
  created() {
    this.getExchanges();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  computed: {
    bttnDisabled() {
      if (this.file) return false;
      return true;
    },
    breiefRowHeight(){
      var h = this.windowHeight-190;
      return { height: h.toString() + "px" }
    }
  },
  methods: {
    mdate(datestr){
      return moment(datestr).format("YYYY-MM-DD HH:mm")
    },
    getExchanges() {
      myAxios
        .get("/api/teleapi/GetVoyagePlans", {
          params: { deviceId: this.deviceid, action: "voyageplan" },
        })
        .then((resp) => {
          this.exchanges = resp.data;
        })
    },
    checkState(exch) {
      this.$set(exch, "checkStateTxt", "checking...");
      myAxios
        .get("/api/teleapi/CheckVoyagePlanState", {
          params: { deviceId: this.deviceid, id: exch.id },
        })
        .then(() => {
          this.getExchanges();
          exch.checkStateTxt =
            "state updated " + new Date().toLocaleString("ru");
        })
        .catch(() => {
          exch.checkStateTxt = "no updates " + new Date().toLocaleString("ru");
        });
    },
    showVoyagePlan(filename) {
      myAxios
        .get("/api/teleapi/GetVoyagePlan", {
          params: { deviceId: this.deviceid, filename: filename + ".json" },
        })
        .then((response) => {
          this.vp = response.data;
        })
    },
    getVoyagePlan(filename) {
      myAxios
        .get("/api/teleapi/GetVoyagePlan", {
          params: { deviceId: this.deviceid, filename: filename},
        })
        .then((response) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(response.data))
          );
          element.setAttribute("download", filename);
          element.style.display = "none";
          element.click();
          URL.revokeObjectURL(element.href);
        })
    },
  },
};
</script>

<style scoped>
.custom-file {
  width: 300px;
  margin-top: 20px;
}

.filelink {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.filelink:hover {
  cursor: pointer;
  color: #0056b3;
  text-decoration: underline;
}

.vpbrief {
  overflow-y: scroll;
}
</style>