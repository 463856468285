<template>
  <div class="row" style="margin-top: -30px" v-if="user && user.licenses">
    <div class="col-md-2 border-right">
      <select v-model="deviceid" class="form-control mb-3">
        <option disabled value="">{{ $t('teleApi.sider.selectDevice') }}</option>
        <option
          v-for="inst in user.licenses[0].installations"
          :key="inst.id"
          v-bind:value="inst.id.toString()"
        >
          {{ inst.nickname ? inst.nickname : inst.deviceId }}
        </option>
      </select>
      <ul id="commandsMenu" class="list-unstyled pl-3">
        <li
          @click="mode = 'sendsettings'"
          v-bind:class="{ active: mode === 'sendsettings' }"
        >
          {{ $t('teleApi.sider.sendSettings') }}
        </li>
        <li
          @click="mode = 'sendvoyage'"
          v-bind:class="{ active: mode === 'sendvoyage' }"
        >
          {{ $t('teleApi.sider.sendVp') }}
        </li>
        <li
          @click="mode = 'voyage'"
          v-bind:class="{ active: mode === 'voyage' }"
        >
          {{ $t('teleApi.sider.getVp') }}
        </li>
        <li
          @click="mode = 'position'"
          v-bind:class="{ active: mode === 'position' }"
        >
          {{ $t('teleApi.sider.getPosition') }}
        </li>
        <li @click="mode = 'trips'" v-bind:class="{ active: mode === 'trips' }">
          {{ $t('teleApi.sider.getTrips') }}
        </li>
        <li
          @click="mode = 'tracks'"
          v-bind:class="{ active: mode === 'tracks' }"
        >
          {{ $t('teleApi.sider.getTracks') }}
        </li>
        <li
          @click="mode = 'appstate'"
          v-bind:class="{ active: mode === 'appstate' }"
        >
          {{ $t('teleApi.sider.getAppState') }}
        </li>
        <hr />
        <li>
          <router-link to="/voyage-plan-editor">{{ $t('teleApi.sider.vpEditor') }}</router-link>
        </li>
        <li><router-link to="/taxi-orders">{{ $t('teleApi.sider.taxiOrders') }}</router-link></li>
        <li><router-link to="/drivers">{{ $t('teleApi.sider.drivers') }}</router-link></li>
      </ul>
    </div>
    <div class="col-md-10 ccMain">
      <div v-if="deviceid || mode === 'installations'">
        <send-settings
          v-if="mode === 'sendsettings'"
          :deviceid="deviceid"
          :key="deviceid"
        ></send-settings>
        <send-voyage-plan
          v-if="mode === 'sendvoyage'"
          :deviceid="deviceid"
          :key="deviceid"
        ></send-voyage-plan>
        <voyage-plan
          v-if="mode === 'voyage'"
          :deviceid="deviceid"
          :key="deviceid"
        ></voyage-plan>
        <get-position
          v-else-if="mode === 'position'"
          :deviceid="deviceid"
          :key="deviceid"
        ></get-position>
        <get-trips
          v-else-if="mode === 'trips'"
          :deviceid="deviceid"
          :key="deviceid"
        ></get-trips>
        <get-tracks
          v-else-if="mode === 'tracks'"
          :deviceid="deviceid"
          :key="deviceid"
        ></get-tracks>
        <get-app-state
          v-else-if="mode === 'appstate'"
          :deviceid="deviceid"
          :key="deviceid"
        ></get-app-state>
      </div>
      <div v-else>{{ $t('teleApi.sider.plsSelectDeviceFirst') }}</div>
    </div>
  </div>
</template>

<script>
import SendVoyagePlan from "./send-voyage-plan";
import VoyagePlan from "./voyage-plan.vue";
import GetPosition from "./get-position.vue";
import GetTracks from "./get-tracks.vue";
import GetTrips from "./get-trips.vue";
import GetAppState from "./get-app-state.vue";
import SendSettings from "./send-settings.vue";

export default {
  components: {
    VoyagePlan,
    GetPosition,
    GetTracks,
    GetTrips,
    SendVoyagePlan,
    GetAppState,
    SendSettings,
  },
  data() {
    return {
      mode: "voyage",
      deviceid: "",
    };
  },
  created() {
    this.$store.dispatch("user/getUser");
    this.deviceid = this.$route.params.devid ?? "";
  },
  computed: {
    status() {
      return this.$store.state.user.status;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
};
</script>

<style scoped>
#commandsMenu li {
  cursor: pointer;
}

#commandsMenu li:hover {
  color: blue;
}

#commandsMenu li.active {
  font-weight: bold;
}

.ccMain {
  padding-left: 5px;
}
</style>