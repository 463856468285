<template>
  <div class="row p-0" style="margin: -16px 0 0 0">
    <div class="col-md-3 left_bar">
      <h5>Route Plan</h5>
      <RouteInfo
        v-if="routeProps"
        :routeInfo="routeProps"
        v-on:postRoutePlan="postRoutePlan"
      />
      <div class="card mt-2">
        <div class="card-body">
          <div>
            <route-item
              v-for="itm in routePlan"
              :key="itm.id"
              :planItem="itm"
              v-on:delItem="delItem"
              class="mb-1"
            />
          </div>
          <p>
            <small
              >Чтобы добавить точку правый клик по карте или нажмите
              кнопку</small
            >
          </p>
          <button class="btn btn-outline-secondary btn-sm" @click="addItem">
            Добавить шаг
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-9 m-0 p-0"><Map /></div>
  </div>
</template>

<script>
import Map from "./Map";
import RouteInfo from "./RouteInfo";
import RouteItem from "@/components/route-plan/route-plan-item.vue";
import { eventBus } from "@/main";
import axios from "axios";
import map from "@/map2.js";

export default {
  components: {
    Map,
    RouteItem,
    RouteInfo,
  },
  data() {
    return {
      routePlan: [],
      planChanged: 0,
      routeProps: null,
      routePoints: [],
      planHeader: {
        id: 123,
        version: 1,
        name: "Уборка мусора",
        organization: "Эко транс",
        driver: "Ивано И.И.",
        car: "А007ВМ178РФ",
        car_type: "Мусоровоз",
        task_type: "Уборка",
        comment: "Использовать только синие баки",
        issueDate: 44229.641273148147,
        executionDate: 44230.0,
        state: "not_started",
        startTime: 0,
        endTime: 0,
      },
    };
  },
  created() {
    eventBus.$on("addRouteItem", (latLng) => {
      this.addItem(latLng);
    });
    eventBus.$on("changeItemLatLng", (data) => {
      var itm = this.routePlan.find((x) => x.id === data.id);
      itm.lat = data.lat;
      itm.lon = data.lng;
      this.planChanged++;
    });
  },
  methods: {
    addItem(latLng) {
      var id = new Date().getTime();
      var itm = {
        id,
        state: "planned",
        startTime: 0,
        endTime: 0,
        type: "goTo",
      };
      if (latLng) {
        itm.lat = latLng.lat;
        itm.lon = latLng.lng;
      }

      this.routePlan.push(itm);
      this.planChanged++;
    },
    delItem(id) {
      this.routePlan = this.routePlan.filter((o) => {
        return o.id !== id;
      });
      this.planChanged++;
    },
    calcRoute() {
      this.routePoints = [];
      var routeCalcParams = {
        type: "OptimizeTime",
        allowYards: true,
        allowSideWays: false,
        allowGroundRoads: true,
        allowPaidRoads: true,
        allowRailwayCrossing: true,
        useJams: false,
        useLimitsUpd: true,
        useForecast: false,
      };

      this.routePlan.forEach((itm) => {
        if (itm.lat && itm.lon)
          this.routePoints.push({ latitude: itm.lat, longitude: itm.lon });
      });
      if (this.routePoints.length >= 2) {
        var routes = new Array();
        routes.push({
          Points: this.routePoints,
        });
        var request = {
          accessKey: "WWWB2BPROBKINET",
          getPoints: true,
          parameters: routeCalcParams,
          routes: routes,
          carParameters: null,
        };
        // if (carparam != null) {
        //     request.carParameters = carparam;
        // } else {
        //     delete request.carParameters;
        // }
        axios
          .post("https://api.probki.net/api/route", request, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            map.drawRoute(resp.data);
            this.routeProps = resp.data.features[0].properties;
            this.routeProps.pointsCount = this.routePoints.length;
          });
      } else {
        this.routeProps = null;
        map.removeRoute();
      }
    },
    postRoutePlan() {
      var plan = [...this.routePlan];
      plan.forEach(itm => delete itm.marker);
      this.planHeader.commands = plan;
      axios
        .put(
          "http://win8fvds.probki.net/messaging/api/deliverytask?login=kit@probki.net&password=1&filename=20210202_1.json",
          JSON.stringify(this.planHeader),
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {});
    },
  },
  watch: {
    planChanged: function () {
      this.calcRoute();
    },
  },
};
</script>

<style scoped>
.left_bar {
  background-color: #f3f3f3;
}
</style>