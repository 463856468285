<template>
  <div
    style="
      border: solid 1px #ced4da;
      border-radius: 5px;
      padding: 15px;
      margin: 5px;
    "
  >
    <h6>{{ item.name }}</h6>
    <command
      v-for="(cmd, index) in item.commands"
      :key="index"
      :cmd="cmd"
      :stored="true"
    />

    <button @click="deleteItem" class="btn btn-sm btn-outline-danger mt-2">Delete Command</button>
    
  </div>
</template>

<script>
import Command from "./Command.vue";

export default {
  components: { Command },
  props: {
    item: {},
  },
  methods:{
      deleteItem(){
          this.$emit("deleteItem", this.item.id);
      }
  }
};
</script>