<template>
  <div class="mt-2">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Message</th>
          <th>Location</th>
          <th>Images</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="msg in messages" :key="msg.id">
        <td>{{msg.text}}</td>
        <td>{{msg.latLng[0].toFixed(6)}} {{msg.latLng[1].toFixed(6)}}</td>
        <td>
          <div v-if="msg.images">
            <CoolLightBox :items="msg.images" :index="index" @close="index = null"></CoolLightBox>

            <div class="images-wrapper">
              <!-- <div
                class="image"
                v-for="(image, indx) in msg.images"
                :key="msg.id + '_' + indx"
                @click="index = indx"
                :style="{ backgroundImage: 'url(' + image + ')' }"
              ></div>-->
              <span v-for="(itm,indx) in msg.images" :key="msg.id + '_' + itm.id" @click="index = msg.id + '_' + indx">
                <img :src="itm" width="80px" class="mr-2" />
              </span>
            </div>
          </div>
        </td>
        <td>
          <button class="btn btn-sm btn-outline-danger">remove</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import CoolLightBox from "vue-cool-lightbox";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      messages: null,
      index: null,
    };
  },
  mounted() {
    myAxios
      .get("/api/navmessages")
      .then((resp) => {
        this.messages = resp.data;
      })
      .catch();
  },
};
</script>

<style scoped>
th {
  color: grey;
}
</style>