<template>
  <div>
    <div class="form-group">
      <label>Address</label>

      <vue-typeahead-bootstrap
        v-model="query"
        :data="suggestions"
        :serializer="(item) => item.address"
        :showAllResults="true"
        @input="fetchSuggestions"
        @hit="onSuggestClick($event)"
      />
    </div>
    <div class="form-group">
      <label>LatLon:</label>
      <input type="text" v-model="latLonStr" class="form-control" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import "bootstrap/scss/bootstrap.scss";
import "vue-simple-suggest/dist/styles.css";
import map from "@/map2";
import { eventBus } from "@/main";

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  data() {
    return {
      address: "",
      latLon: null,
      latLonStr: "",
      addressesByLatLng: [],
      query: "",
      streetAC: [],
      houseAC: [],
      geocodeAC: [],
      timeout: 0,
    };
  },
  computed: {
    suggestions() {
      var sug = [];
      sug = [...this.houseAC, ...this.geocodeAC, ...this.streetAC];

      var unique = [];
      var distinct = [];
      for (let i = 0; i < sug.length; i++) {
        if (!unique[sug[i].address]) {
          distinct.push(sug[i]);
          unique[sug[i].address] = 1;
        }
      }

      return distinct;
    },
    mapLatLng() {
      return this.$store.getters["accountSettings/latLon"];
    },
  },
  mounted() {
    eventBus.$on("changeVPCmdLatLng", this.onMarkerDarg);
    eventBus.$on("addRouteItem", this.onRightClickOnMap);
  },
  beforeDestroy() {
    map.deleteSingleMarker();
    eventBus.$off("changeVPCmdLatLng", this.onMarkerDarg);
    eventBus.$off("addRouteItem", this.onRightClickOnMap);
  },
  methods: {
    fetchSuggestions() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.streetautocomplete();
        this.houseautocomplete();
        this.geocodeAddress();
      }, 500);
    },
    streetautocomplete() {
      let addresses = [];
      axios
        .post("http://api.probki.net/search/streetautocomplete", {
          center: { lat: 59.947219, lon: 30.345161 },
          distanceinkm: 50,
          streetFirstLetters: this.query,
          accesskey: "WWWB2BPROBKINET",
        })
        .then((resp) => {
          if (resp.data.result === "Ok") {
            if (resp.data.addresses) {
              resp.data.addresses.forEach((addr) => {
                addresses.push({
                  address: this.addressToString(addr),
                  location: addr.location,
                });
              });
            }
          }
          this.streetAC = addresses;
        })
        .catch(() => {
          this.streetAC = addresses;
        });
    },
    houseautocomplete() {
      let addresses = [];
      axios
        .post("http://api.probki.net/search/houseautocomplete", {
          center: { lat: this.mapLatLng[0], lon: this.mapLatLng[1] },
          distanceinkm: 50,
          address: this.query,
          accesskey: "WWWB2BPROBKINET",
        })
        .then((resp) => {
          if (resp.data.result === "Ok") {
            if (resp.data.addresses) {
              resp.data.addresses.forEach((addr) => {
                addresses.push({
                  address: this.addressToString(addr),
                  location: addr.location,
                });
              });
            }
          }
          this.houseAC = addresses;
        })
        .catch(() => {
          this.houseAC = addresses;
        });
    },
    geocodeAddress() {
      let addresses = [];
      axios
        .post("http://api.probki.net/search/geocode", {
          center: { lat: this.mapLatLng[0], lon: this.mapLatLng[1] },
          distanceinkm: 50,
          address: this.query,
          accesskey: "WWWB2BPROBKINET",
        })
        .then((resp) => {
          if (resp.data.result !== "Not found") {
            if (resp.data.addresses) {
              resp.data.addresses.forEach((addr) => {
                addresses.push({
                  address: this.addressToString(addr),
                  location: addr.location,
                });
              });
            }
          }
          this.geocodeAC = addresses;
        })
        .catch(() => {
          this.geocodeAC = addresses;
        });
    },
    onMarkerDarg(latLng) {
      this.latLon = {
        lat: Number(latLng.lat.toFixed(6)),
        lon: Number(latLng.lng.toFixed(6)),
      };
      this.latLonStr = latLng.lat.toFixed(6) + " " + latLng.lng.toFixed(6);
      this.suggestByLatLng(latLng);
    },
    onRightClickOnMap(latLng) {
      this.onMarkerDarg(latLng);
      map.singleMarkerForSetRoutePoint(latLng.lat, latLng.lng);
    },
    suggestByLatLng(latLng) {
      let addresses = [];
      axios
        .post("http://api.probki.net/search/nearestaddress", {
          center: { lat: latLng.lat, lon: latLng.lng },
          accesskey: "WWWB2BPROBKINET",
        })
        .then((resp) => {
          if (resp.data.result === "Ok") {
            if (resp.data.addresses) {
              resp.data.addresses.forEach((addr) => {
                addresses.push({
                  address: this.addressToString(addr),
                  location: addr.location,
                });
              });
            }
            this.address = addresses[0].address;
            this.query = addresses[0].address;
            this.passLocation();
          } else {
            this.address = "";
          }
        });
    },
    autocomplete(val) {
      return new Promise((resolve, reject) => {
        axios
          .post("http://api.probki.net/search/houseautocomplete", {
            center: { lat: this.mapLatLng[0], lon: this.mapLatLng[1] },
            distanceinkm: 50,
            address: val,
            accesskey: "WWWB2BPROBKINET",
          })
          .then((resp) => {
            if (resp.data.result === "OK") {
              let addresses = [];
              if (resp.data.addresses) {
                resp.data.addresses.forEach((addr) => {
                  addresses.push({
                    address: this.addressToString(addr),
                    location: addr.location,
                  });
                });
                resolve(addresses);
              }
              reject();
            }
          });
      });
    },
    onSuggestClick(suggest) {
      this.latLon = {
        lat: Number(suggest.location.lat.toFixed(6)),
        lon: Number(suggest.location.lon.toFixed(6)),
      };
      this.latLonStr =
        suggest.location.lat.toFixed(6) + " " + suggest.location.lon.toFixed(6);
      this.address = suggest.address;
      map.singleMarkerForSetRoutePoint(
        suggest.location.lat.toFixed(6),
        suggest.location.lon.toFixed(6)
      );
    },
    addressToString(address) {
      return address.city + ", " + address.street + " " + address.house;
    },
    passLocation() {
      this.$emit("setLocation", {
        address: this.address,
        latLon: this.latLon,
      });
    },
  },
  watch: {
    latLon: function () {
      if (this.latLon) {
        this.passLocation();
      }
    },
  },
};
</script>

<style scoped>
.cmd-form {
}
</style>