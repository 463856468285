import L from 'leaflet';

let map = {};

export default {
    init(el, taskId) {
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });

        map = L.map(el).setView([58.062411, 6.836087], 15);

        L.tileLayer('http://84.52.88.202:8091/map/{z}/{x}/{y}?taskId=' + taskId, {
            maxZoom: 18,
            maxNativeZoom: 18,
            minZoom: 3,
            attribution: "Map data &copy; <a href='http://probki.net/b2b'>MIT GEOMATICS</a>"
        }).addTo(map);

        var marker = L.marker([58.186443, 8.521055]).addTo(map);
    },
    generateRandInt() {
        return Math.floor( Math.random() * 200000 ) + 1;
    }
}