<template>
  <div>
    <!-- <h3>{{ $t('user.cabinet') }}</h3>
    <hr /> -->
    <div v-if="status.loaded">
      <!-- <p>Email: {{user.email}}</p> -->
      <user-licenses v-bind:licenses="user.licenses" v-if="user.licenses.length > 0"></user-licenses>
    </div>
  </div>
</template>

<script>
import UserLicenses from "@/components/UserLicenses";
//import ShopCatalog from "../views/eshop/Catalog";

export default {
  name: "UserCabinet",
  components: { UserLicenses },
  created() {
    this.$store.dispatch("user/getUser");
  },
  computed: {
    status() {
      return this.$store.state.user.status;
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>