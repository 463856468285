<template>
  <div>
    <div v-if="mapsCollections">
      <div v-for="collection in mapsCollections" :key="collection.id">
        <h5>{{ collection.name }}</h5>
        <add-map :mapsCollectionId="collection.id" />
        <table class="table table-sm">
          <thead>
            <tr>
              <td>Map name</td>
              <td>File name</td>
              <td style="width:180px"></td>
              <td></td>
              <td>UID</td>
              <td>Subst.UID</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <update-map v-for="map in collection.maps" :key="map.id" :map="map" />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AddMap from "@/components/geocontent/AddMap.vue";
import UpdateMap from "@/components/geocontent/UpdateMap.vue";

export default {
  components: { AddMap, UpdateMap },
  created() {
    this.$store.dispatch("mapsCollections/getMapsCollection");
  },
  computed: {
    status() {
      return this.$store.state.mapsCollections.status;
    },
    mapsCollections() {
      return this.$store.state.mapsCollections.mapsCollections;
    },
  },
};
</script>