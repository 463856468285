import * as userService from '@/services/userService'
const initialState = {
    user: null,
    status: null
}

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        getUser({ dispatch, commit }) {
            commit('userRequest');
            userService.getUser()
                .then(resp => {
                    var user = resp.data;
                    if (user) {
                        commit('userSuccess', user);
                    }
                    else {
                        commit('userFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('userFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        userRequest(state) {
            state.status = { loading: true };
            state.user = null;
        },
        userSuccess(state, user) {
            state.status = { loaded: true };
            state.user = user;
        },
        userFailure(state) {
            state.status = null;
            state.user = null;
        }
    }
}