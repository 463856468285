<template>
  <div>
    {{ file.name }} | {{ file.size }} bytes | {{ file.timestamp }}
    <input
      type="text"
      class="form-control"
      v-model="file.description"
      placeholder="Description"
      @input="changeFileDescription()"
    />
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    file: {
      type: Object,
      default: () => {
        return { name: "" };
      },
    },
  },
  data() {
    return {
      awaitingInput: false,
    };
  },
  methods: {
    changeFileDescription() {
      if (!this.awaitingInput) {
        setTimeout(() => {
          var mfd = { fileId: this.file.id, description: this.file.description };
          mfd = JSON.stringify(mfd);
          myAxios.post("/maps/FileDescription", mfd, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          this.awaitingInput = false;
        }, 2000);
      }
      this.awaitingInput = true;
    },
  },
};
</script>