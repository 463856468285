<template>
  <div>
    <div class="d-none d-xl-block">
      <div style="margin-left:-7px;" class="mb-1">
        <!-- <img src="../assets/images/mkart_logo.jpg" width="50px" /> -->
        <span class="ml-2 brandname align-bottom">bGeo</span>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="col-md-9">
        <sign-up-sign-in v-if="!auth.status.loggedIn"></sign-up-sign-in>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpSignIn from "@/components//signUpSignIn/index.vue";

export default {
  components: {
    SignUpSignIn,
  },

  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    auth() {
      return this.$store.state.auth;
    }
  }
};
</script>

<style scoped>
.brandname {
  font-size: xx-large;
  font-weight: bold;
}

#user_types > div {
  background-color: #eee9e9;
  border-radius: 5px;
}

#user_types > div:hover {
  background-color: #ffe2aa;
}

/* #user_types > div > h3 {
  margin-bottom: 50px;
} */

a:not([href]) {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.card-text {
  font-size: small;
}
</style>