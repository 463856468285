<template>
  <div v-if="vp" :style="{ height: windowHeight + 'px' }">
    <!-- <p>{{ new Date(vp.issueDate).toDateString() }} {{ vp.organization }}</p> -->
    <div><strong>Путевой лист</strong></div>
    <div>{{ vp.name }} от {{new Date(vp.issueDate).toLocaleDateString()}}</div>
    <div><strong>Исполнитель</strong></div>
    <div>{{ vp.driver }}</div>
    <div><strong>а/м</strong> {{ vp.car }} {{ vp.carType }}</div>
    <ul class="list-unstyled mt-4">
      <li v-for="itm in vp.items" :key="itm.id" class="taskItem">
        <h6>Задание {{ itm.name }}</h6>
        <ul class="list-unstyled">
          <li
            v-for="(cmd, indx) in itm.commands"
            :key="indx"
            @click="setCurrentCmd(cmd)"
            class="clickable"
            v-bind:class="{ active: isActiveByLatLng(cmd) }"
          >
            <vp-index-item :cmd="cmd"></vp-index-item>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import vpIndexItem from "./vp-index-item.vue";

export default {
  components: { vpIndexItem },
  props: {
    vp: null,
    activeCmd: null,
  },
  data() {
    return {
      windowHeight: window.innerHeight-120,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight - 120;
    });
  },
  methods: {
    setCurrentCmd(cmd) {
      this.$emit("setCurrentCmd", cmd);
    },
    isActiveByLatLng(cmd) {
      if (this.activeCmd) {
        return cmd.lat == this.activeCmd.lat && cmd.lon == this.activeCmd.lon && cmd.plannedTimeStart === this.activeCmd.plannedTimeStart;
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: rgb(223, 223, 223);
}

ul {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}

.active {
  background-color: rgb(205, 235, 206);
}

.taskItem {
  border: solid 1px rgb(218, 218, 218);
  padding: 5px;
}
</style>