<template>
  <div>
    <h5 class="my-3">{{ $t('distributor.prods.avbl_prods') }}</h5>
    <div v-if="err">{{err}}</div>
    <table v-if="allProducts" class="table table-sm">
      <thead>
        <tr>
          <th>{{ $t('distributor.prods.title') }}</th>
          <th>{{ $t('distributor.prods.publisher') }}</th>
          <th>{{ $t('distributor.prods.prod') }}</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="prod in allProducts" :key="prod.id">
        <td>{{prod.product.geoContent.title}}</td>
        <td>{{prod.product.geoContent.provider.title}}</td>
        <td>{{prodShort(prod.product)}}</td>
        <td>
          <span v-if="prod.status == null">
            <button
              class="btn btn-sm btn-outline-primary"
              @click="requestPermit(prod.product.id)"
            >{{ $t('distributor.prods.request_permit') }}</button>
          </span>
          <span v-if="prod.status === 'pending'">
            <button
              class="btn btn-sm btn-warning"
            >{{ $t('distributor.prods.pending') }}</button>
          </span>
          <span v-if="prod.status === 'accepted'">
            <button
              class="btn btn-sm btn-success"
            >{{ $t('distributor.prods.accepted') }}</button>
          </span>
          <span v-if="prod.status === 'rejected'">
            <button
              class="btn btn-sm btn-danger"
            >{{ $t('distributor.prods.rejected') }}</button>
          </span>
          <span v-if="prod.status === 'paused'">
            <button
              class="btn btn-sm btn-warning"
            >{{ $t('distributor.prods.paused') }}</button>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      allProducts: null,
      err: null
    };
  },
  created() {
      this.getAllProducts();
  },
  methods: {
    requestPermit(gcid) {
      myAxios
        .get("/api/distributors/RequestPermition?gcid=" + gcid)
        .then(resp => {
          this.getAllProducts();
        })
        .catch(err => {});
    },
    getAllProducts() {
      myAxios
        .get("/api/distributors/GetAllProducts")
        .then(resp => {
          this.allProducts = resp.data;
        })
        .catch(err => {});
    },
    prodShort(prod){
      var short = prod.licType.toUpperCase() + " [";
      if(prod.enc) short += "ENC, "
      if(prod.ais) short += "AIS, "
      if(prod.ntm) short += "NTM, "
      if(prod.poi) short += "POI, "
      if(prod.weather) short += "WTHR, "
      if(prod.navMsg) short += "NMSG, "
      short = short.slice(0, -2);
      short += "]"
      return short
    }
  }
};
</script>