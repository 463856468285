<template>
  <div>
    <!-- <p>GeoContent Provider Cabinet</p>
    <hr /> -->
    <div v-if="provider" class="d-flex flex-row align-items-baseline">
      <div v-if="geoContent">
        <h4>{{geoContent.title}}</h4>
      </div>
      <div v-if="geoContent" class="mx-3"><p>{{ $t('publisher.publishedby') }}</p></div>
      <div>
        <h4>{{provider.title}}</h4>
      </div>
    </div>

    <div v-if="geoContent">
      <router-view name="providerView" :gcid="geoContent.id"></router-view>
    </div>
    <!-- <add-geo-content v-if="!geoContent && status.loaded"></add-geo-content> -->
  </div>
</template>

<script>
//import AddGeoContent from "@/components/geocontent/AddGeoContent.vue";

export default {
  name: "ContentProviderCabinet",
  // components: {
  //   AddGeoContent
  // },
  created() {
    this.$store.dispatch("provider/getProvider");
  },
  computed: {
    status() {
      return this.$store.state.provider.status;
    },
    provider() {
      return this.$store.state.provider.provider;
    },
    geoContent() {
      if (this.provider && this.provider.geoContents.length > 0) {
        return this.provider.geoContents[0];
      }
      return null;
    }
  },
  // watch: {
  //   geoContent: function() {
  //     if (this.geoContent) {
  //       this.$router.push({
  //         name: "geocontent",
  //         params: {gcid: this.geoContent.id }
  //       });
  //     }
  //   }
  // }
};
</script>