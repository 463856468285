<template>
  <div class="row">
    <div class="col-md-6">
      <h4 class="mb-4">{{ $t('registration.title') }}</h4>
      <div v-if="registration && registration.failure" class="alert alert-danger">
        {{registration.message.text}}.
        <span
          v-if="registration.message.code === 'user_exist'"
          class="alert-link"
        >{{ $t('login.forgot.password') }}</span>
      </div>
      <form @submit.prevent="handleSubmit" class="form">
        <!-- <div class="form-group">
          <label for="name">Title</label>
          <input class="form-control" type="name" name="name" v-model="name" />
        </div>-->
        <div class="form-group">
          <label for="username">Email</label>
          <input class="form-control" type="email" name="email" v-model="email" />
        </div>
        <!-- <div class="form-group">
          <label for="password">Password</label>
          <input class="form-control" type="password" name="password" v-model="password" />
        </div>-->
        <div class="form-group">
          <button class="btn btn-primary mr-2">{{ $t('registration.submit') }}</button>
          <p>
            {{ $t('registration.already.registered') }} -
            <a
              class="reg-link"
              @click="switchMode('login')"
            >{{ $t('registration.login') }}</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //name: "",
      email: ""
    };
  },
  computed: {
    registration() {
      return this.$store.state.auth.registration;
    }
  },
  methods: {
    handleSubmit() {
      if (this.email) {
        this.$store.dispatch("auth/register", {
          email: this.email,
          //   name: this.name,
          userType: "user"
        });
      }
    },
    switchMode(mode) {
      this.$emit("changeMode", mode);
    }
  }
};
</script>

<style scoped>
.reg-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>