<template>
  <div class="pt-3" v-if="cmd">
    <vp-cmd-goto v-if="cmd.type === 'goTo'" :cmd="cmd"></vp-cmd-goto>
    <vp-cmd-execute v-if="cmd.type === 'execute'" :cmd="cmd"></vp-cmd-execute>
  </div>
</template>

<script>
import VpCmdExecute from "./vp-cmd-execute.vue";
import vpCmdGoto from "./vp-cmd-goto.vue";

export default {
  components: { vpCmdGoto, VpCmdExecute },
  props: {
    cmd: {}
  },
};
</script>