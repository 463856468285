<template>
  <div class="row mt-3">
    <div class="col-md-4">
      <div class="list-group">
        <div class="list-group-item" v-for="ap in avblProducts" :key="ap.geoContent.id">
          <h6>{{ap.geoContent.title}}</h6>
          <ul class="list-unstyled">
            <li
              v-for="prod in ap.products"
              :key="prod.id"
              @click="setCurrentProd(prod)"
              class="clickable"
              v-bind:class="{ active: isActive(prod.product.id) }"
            >
              <span v-html="prodShort(prod.product)"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div v-if="currentProd">
        <p>
          <strong>{{currentProd.product.geoContent.title}}</strong>
          <span
            class="px-2 py-1 ml-1"
            style="background-color:#eee;"
            v-html="prodShort(currentProd.product)"
          ></span>
        </p>
        <add-user-permit v-if="currentProd.status==='accepted'" :prod="currentProd"></add-user-permit>
        <div v-if="issuedLicenses && issuedLicenses.length" class="mt-3">
          <h6>{{ $t('distributor.lics.issued_lics') }}</h6>
          <table class="table table-sm mt-3">
            <thead>
              <tr>
                <th>{{ $t('distributor.lics.issue_date') }}</th>
                <th>{{ $t('distributor.lics.exp_date') }}</th>
                <th>{{ $t('distributor.lics.dev_num') }}</th>
                <th>{{ $t('distributor.lics.userid') }}</th>
              </tr>
            </thead>
            <tr v-for="lic in issuedLicenses" :key="lic.id">
              <td><my-date :date="lic.issued"></my-date></td>
              <td><my-date :date="lic.expirationDate"></my-date></td>
              <td>{{lic.deviceCount}}</td>
              <td>{{lic.user.email}}</td>
            </tr>
          </table>
        </div>
      </div>
      <p v-else>{{ $t('distributor.lics.chose_prod') }}</p>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import AddUserPermit from "@/components/distributor/AddUserPermit.vue";

export default {
  components: {
    AddUserPermit
  },
  data() {
    return {
      avblProducts: null,
      currentProd: null
    };
  },
  computed: {
    issuedLicenses() {
      return this.$store.state.distributor.issuedLicenses;
    }
  },
  created() {
    myAxios
      .get("/api/distributors/getallowedproducts")
      .then(resp => {
        if (resp.data.length == 0) {
          this.$router.push({ name: "distributor_products" });
        } else {
          this.avblProducts = resp.data;
          this.setCurrentProd(this.avblProducts[0].products[0]);
        }
      })
  },
  methods: {
    setCurrentProd(prod) {
      this.currentProd = prod;
      this.$store.dispatch(
        "distributor/getIssuedLicenses",
        this.currentProd.product.id
      );
    },
    isActive(prodid) {
      var act = this.currentProd && this.currentProd.product.id === prodid ? true : false;
      return act;
    },
    prodShort(prod) {
      var short =
        "<small><strong>" + prod.licType.toUpperCase() + "</strong> [";
      if (prod.enc) short += "ENC, ";
      if (prod.ais) short += "AIS, ";
      if (prod.ntm) short += "NTM, ";
      if (prod.poi) short += "POI, ";
      if (prod.weather) short += "WTHR, ";
      if (prod.navMsg) short += "NMSG, ";
      short = short.slice(0, -2);
      short += "]</small>";
      return short;
    }
  }
};
</script>

<style scoped>
th {
  color: grey;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #007bff;
}
.active {
  background-color: #bfdeff;
  padding:0 4px;
}
</style>