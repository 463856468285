<template>
    <div id="mymap" ref="mm" :style="{ height: windowHeight + 'px' }"></div>
</template>

<script>
import map from "@/map2.js";
import "leaflet/dist/leaflet.css";
//import myAxios from "@/services/myAxios";
//import moment from "moment";

export default {
  data() {
    return {
      windowHeight: window.innerHeight-190,
      // isOld: false,
      // webMapTimeStamp: null,
      // gcTimeStamp: null
    };
   },
  // computed: {
  //   provider() {
  //     return this.$store.state.provider.provider;
  //   },
  //   geoContent() {
  //     if (this.provider && this.provider.geoContents.length > 0) {
  //       return this.provider.geoContents[0];
  //     }
  //     return null;
  //   }
  // },
  // created() {
  //   this.$store.dispatch("provider/getProvider");
  // },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight-190;
    });

    map.init(this.$refs.mm, this.taskId);
  },
  // methods: {
  //   checkUpd() {
  //     this.webMapTimeStamp = moment.utc(this.getTilesUpDate());
  //     var tid = this.getRenderTaskId();
  //     this.gcTimeStamp = moment(this.geoContent.upDate);
  //     if (moment(this.webMapTimeStamp).isBefore(moment(this.gcTimeStamp))) {
  //       myAxios.get("http://84.52.88.202:8091/update");
  //       this.isOld = true;
  //     }
  //   },
  //   getRenderTaskId() {
  //     myAxios
  //       .get("api/geocontent/gotomap?gcid=" + this.geoContent.id)
  //       .then(resp => {
  //         return resp.data;
  //       });
  //   },
  //   getTilesUpDate() {
  //     myAxios.get("http://84.52.88.202:8091/mintime").then(resp => {
  //       return resp.data;
  //     });
  //   },
  //   mmoment(date) {
  //     if (date) return moment(date).format("YYYY-MM-DD HH:mm:ss");
  //     return "unlimited";
  //   }
  // },
  // watch: {
  //   geoContent: function() {
  //     this.gcTimeStamp = moment(this.geoContent.upDate);
  //     this.checkUpd();
  //   }
  // }
};
</script>

<style scoped>
#mymap{
  border:solid 1px dimgray;
}
</style>