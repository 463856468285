<template>
  <div
    style="
      border: 1px solid #ced4da;
      border-radius: 5px;
      margin: 5px;
      padding: 15px;
    "
    @click="onClick()"
    ref="cmdid"
  >
    <vp-cmd-goto v-if="cmd.type === 'goTo'" :cmd="cmd" />
    <vp-cmd-execute v-if="cmd.type === 'execute'" :cmd="cmd" />
    <div v-if="!stored">
      <button class="btn btn-sm btn-outline-danger mt-2" @click="deleteCommand">Delete Command</button>
    </div>
  </div>
</template>

<script>
import vpCmdGoto from "../voyage-planer/vp-cmd-goto.vue";
import vpCmdExecute from "../voyage-planer/vp-cmd-execute.vue";
import map from "@/map2";

export default {
  components: {
    vpCmdGoto,
    vpCmdExecute,
  },
  props: {
    cmd: {},
    stored: Boolean,
  },
  methods: {
    deleteCommand() {
      this.$emit("deleteCommand", this.cmd);
    },
    onClick() {
      map.setView({ lat: this.cmd.lat, lon: this.cmd.lon });
    },
  },
};
</script>