<template>
  <div>
    <div class="vpTitle">
      <h6>{{ vp.name }}</h6>
      {{ vp.organization }} {{ vp.driver }} {{ vp.car }} {{ vp.carType }}
      {{ vp.taskType }}
    </div>
    <ol>
      <li v-for="itm in vp.items" :key="itm.id">
        <strong>{{ itm.name }}</strong>
        <ol class="cmdList">
          <li
            v-for="(cmd, indx) in itm.commands"
            :key="indx"
            :style="stateColor(cmd.state)"
          >
            <div><strong>Type:</strong> {{ cmd.type }}</div>
            <div><strong>State:</strong> {{ cmd.state }}</div>
            <div><strong>Action:</strong> {{ cmd.action }}</div>
            <div><strong>Address:</strong> {{ cmd.address }}</div>
            <div>
              <strong>Location:</strong>
              <span
                class="clickable"
                v-if="cmd.lat"
                @click="showOnMap(cmd.lat, cmd.lon)"
                >{{ cmd.lat }} {{ cmd.lon }}</span
              >
            </div>
            <div
              v-if="
                cmd.timeWindowStart &&
                cmd.timeWindowStart !== '1970-01-01T02:59:59'
              "
            >
              <strong>Due DateTime: </strong>
              {{ new Date(cmd.timeWindowStart).toLocaleString("ru") }} -
              {{ new Date(cmd.timeWindowEnd).toLocaleTimeString("ru") }}
            </div>
            <div v-if="cmd.estimatedTime">
              <strong>ETA:</strong>
              {{ new Date(cmd.estimatedTime).toLocaleString("ru") }}
            </div>
            <div v-if="cmd.completedTime">
              <strong>Fact:</strong>
              {{ new Date(cmd.completedTime).toLocaleString("ru") }}
            </div>
            <div v-if="cmd.estimatedTime && cmd.completedTime">
              <strong>Delta: </strong>
              {{ getTimeDelta(cmd.timeWindowEnd, cmd.completedTime) }}
            </div>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import map from "@/map2.js";

export default {
  props: {
    vp: {},
  },
  methods: {
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
    stateColor(state) {
      switch (state) {
        case "done":
          return { backgroundColor: "#b2ffc0" };
        case "suspended":
          return { backgroundColor: "#ffd9aa" };
        case "started":
          return { backgroundColor: "#b5e3ff" };
        default:
          return {};
      }
    },
    getTimeDelta(plan, fact) {
      if (plan && fact) {
        var diff = new Date(fact) - new Date(plan);

        if (diff > 0) {
          return (
            "delay " + new Date(Math.abs(diff)).toISOString().slice(11, 19)
          );
        } else if (diff < 0) {
          return (
            "ahead " + new Date(Math.abs(diff)).toISOString().slice(11, 19)
          );
        } else {
          return "in time";
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.cmdList {
  margin-left: -30px !important;
  font-size: 14px;
  line-height: 18px;
}

.cmdList li {
  margin: 5px 0;
  padding: 5px;
}

.cmdList strong {
  font-weight: 500;
  color: rgb(70, 70, 70);
}

.clickable {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.vpTitle {
  font-size: 15px;
  line-height: 19px;
}

.vpTitle h6 {
  padding-top: 5px;
}
</style>