<template>
  <div v-if="cmd">
    <div><strong>Type:</strong> {{ cmd.type }}</div>
    <div><strong>State:</strong> {{ cmd.state }}</div>
    <div><strong>Action:</strong> {{ cmd.action }}</div>
    <div><strong>Address:</strong> {{ cmd.address }}</div>
    <div>
      <strong>Location:</strong>
      <span
        class="clickable"
        v-if="cmd.lat"
        @click="showOnMap(cmd.lat, cmd.lon)"
        >{{ cmd.lat }} {{ cmd.lon }}</span
      >
    </div>
    <div>
      <strong>Planned Time: </strong>
      {{ new Date(cmd.plannedTimeStart).toLocaleString("ru") }} -
      {{ new Date(cmd.plannedTimeEnd).toLocaleTimeString("ru") }}
    </div>
    <div v-if="cmd.estimatedTime">
      <strong>ETA:</strong>
      {{ new Date(cmd.estimatedTime).toLocaleString("ru") }}
    </div>
    <div v-if="cmd.completedTime">
      <strong>Fact:</strong>
      {{ new Date(cmd.completedTime).toLocaleString("ru") }}
    </div>
    <div v-if="cmd.estimatedTime && cmd.completedTime">
      <strong>Delta:</strong> {{ calculateDelay(cmd.plannedTimeStart, cmd.estimatedTime) }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    cmd: null,
  },
  methods:{
    calculateDelay(planned, eta) {
      if (planned && eta) {
        var diff = new Date(eta) - new Date(planned);

        if (diff > 0) {
          return (
            "delay " +  moment.duration(Math.abs(diff)) + " min"
          );
        } else if (diff < 0) {
          return (
            "ahead " +  moment.duration(Math.abs(diff)) + " min"
          );
        } else {
          return "in time";
        }
      }
      return null;
    }
  }
};
</script>