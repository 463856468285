<template>
  <div>
    <upload></upload>
    <raw-list></raw-list>
  </div>
</template>
<script>
import RawList from "./RawList"
import Upload from "./Upload"
export default {
  components: { Upload, RawList }
};
</script>