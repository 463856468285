<template>
  <div>
    <add-enc class="mt-2 mb-4"></add-enc>
    <div v-if="status && status.loaded">
      <p>{{ $tc('publisher.gc.cat.enc.list.total', chartCollection.length) }}</p>
      <table class="table table-sm" v-if="chartCollection.length > 0">
        <thead>
          <tr>
            <th>{{ $t('publisher.gc.cat.enc.list.chartName') }}</th>
            <th>{{ $t('publisher.gc.cat.enc.list.ver') }}</th>
            <th>{{ $t('publisher.gc.cat.enc.list.lastUpd') }}</th>
            <th>{{ $t('publisher.gc.cat.enc.list.scale') }}</th>
            <th>{{ $t('publisher.gc.cat.enc.list.band') }}</th>
            <th>{{ $t('publisher.gc.cat.enc.list.descr') }}</th>
          </tr>
        </thead>
        <tr v-for="chart in chartCollection" :key="chart.id">
          <td>{{chart.name}}</td>
          <td>{{chart.edition}}.{{chart.update}}</td>
          <td><my-date :date="chart.releaseDate"></my-date></td>
          <td>{{chart.scale}}</td>
          <td>{{chart.band}}</td>
          <td>{{chart.description}}</td>
          <!-- <td><button class="btn btn-sm btn-outline-secondary">downgrade</button><button class="btn btn-sm btn-outline-secondary">remove</button></td> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import AddEnc from "@/components/geocontent/AddEnc";

export default {
  components: { AddEnc },
  created() {
    this.$store.dispatch("chart/getChartCollection");
  },
  computed: {
    status() {
      return this.$store.state.chart.status;
    },
    chartCollection() {
      return this.$store.state.chart.chartCollection.charts;
    }
  }
};
</script>

<style scoped>
th {
  color: grey;
}
</style>