<template>
  <div style="margin-top: -30px">
    <div>
      <ul class="nav ml-3">
        <li class="nav-item">
          <a class="nav-link active" href="#">Основные</a>
        </li>
        <li class="nav-item">
          <router-link :to="{name:'zoneeditor'}" class="nav-link"
            >Тарифные Зоны</router-link
          >
        </li>
        <li class="nav-item">
          <router-link :to="{name:'taxirates'}" class="nav-link"
            >Тарифы</router-link
          >
        </li>
        <li class="nav-item">
          <router-link :to="{name:'servicezoneeditor'}" class="nav-link"
            >Зоны обслуживания</router-link
          >
        </li>
      </ul>
      <hr />
    </div>
    <button class="btn btn-primary bnt-sm" @click="saveSettings">
      Save Settings
    </button>
    <p>{{ saveSettingsStatus }}</p>
    <div>
      <div class="form-group" style="width: 250px">
        <h5>Currency</h5>
        <select v-model="settings.currency" class="form-control">
          <option value="" disabled selected>select one</option>
          <option value="RUB">RUB</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select>
      </div>
      <taxi-rates />
      <h5>Default location</h5>
      <div class="row">
        <div class="form-group col-2">
          <label>Lat</label
          ><input type="text" v-model="settings.mapLat" class="form-control" />
        </div>
        <div class="form-group col-2">
          <label>Lon</label
          ><input type="text" v-model="settings.mapLon" class="form-control" />
        </div>
      </div>
      <div>
        <m-map></m-map>
      </div>
    </div>
  </div>
</template>

<script>
import MMap from "./Map.vue";
import { eventBus } from "@/main";
import map from "@/map2.js";
import TaxiRates from "./TaxiRates.vue";

export default {
  components: { MMap, TaxiRates },
  data() {
    return {
      saveStatusNotificationText: "",
    };
  },
  computed: {
    settings() {
      return this.$store.state.accountSettings.settings;
    },
    saveSettingsStatus() {
      return this.$store.state.accountSettings.saveSettingsStatus;
    },
  },
  mounted() {
    this.loadSettings();
    eventBus.$on("addRouteItem", this.onRightClickOnMap);
  },
  beforeDestroy() {
    eventBus.$off("addRouteItem", this.onRightClickOnMap);
  },
  methods: {
    loadSettings() {
      this.$store.dispatch("accountSettings/getSettings");
    },
    onRightClickOnMap(latLng) {
      this.settings.mapLat = latLng.lat.toFixed(6);
      this.settings.mapLon = latLng.lng.toFixed(6);
      map.singleMarker(latLng.lat, latLng.lng, 11);
    },
    saveSettings() {
      this.$store.dispatch("accountSettings/saveSettings", this.settings);
    },
  },
};
</script>