<template>
  <div id="app">
    <notifications />
    <nav
      class="navbar navbar-light mb-3"
      style="background-color: #e3f2fd; padding: 0"
      v-if="this.$route.name !== 'map'"
    >
      <loc-link class="ml-3 navbar-brand clickable" :to="'/'">
        <!-- <img src="./assets/images/mkart_logo.jpg" width="30" /> -->
        <span class="align-bottom ml-2">
          <strong>bGeo</strong>
        </span>
      </loc-link>

      <form
        class="form-inline user-roles"
        v-if="auth.status.loggedIn"
        v-on:submit.prevent
      >
        <loc-link
          :rltag="'button'"
          :to="'/user'"
          :class="cabBtnClass('user')"
          >{{ $t("nav.user") }}</loc-link
        >
        <loc-link
          :rltag="'button'"
          :to="'/provider'"
          :class="cabBtnClass('provider')"
          >{{ $t("nav.publisher") }}</loc-link
        >
        <!-- <loc-link
          :rltag="'button'"
          :to="'/distributor'"
          :class="cabBtnClass('distributor')"
        >{{ $t('nav.distributor') }}</loc-link> -->
      </form>

      <span class="navbar-text mr-3">
        <span v-if="auth.status.loggedIn">
          {{ auth.user.email }}
          <button class="btn btn-sm btn-primary" @click="logout">
            {{ $t("main.nav.logout") }}
          </button>
        </span>
        <span class="ml-5">
          <router-link :to="path">EN</router-link> |
          <router-link :to="'/ru' + path">RU</router-link>
        </span>
      </span>
    </nav>

    <notifications />

    <div
      v-if="
        this.$route.name !== 'map' &&
        this.$route.name !== 'route-plan' &&
        this.$route.name !== 'voyageplaneditor' &&
        this.$route.name !== 'taxiorders' &&
        this.$route.name !== 'installations' &&
        this.$route.name !== 'zoneeditor' &&
        this.$route.name !== 'teleapi'
      "
      class="container"
    >
      <div v-if="alert.message">{{ alert.message }}</div>
      <router-view></router-view>
    </div>
    <div v-else class="container-fluid">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      path: "/",
    };
  },
  mounted() {
    if (this.auth && this.auth.user) {
      this.$store.dispatch("accountSettings/getSettings");
    }
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    auth() {
      return this.$store.state.auth;
    },
    locale() {
      return this.$store.getters.locale;
    },
  },
  watch: {
    $route(to) {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
      this.path = this.locale.base
        ? to.path.substring(this.locale.base.length)
        : to.path;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    switchTo(val) {
      this.$router.push("/" + val);
    },
    cabBtnClass(val) {
      var path = this.$route.path;
      var cab = path.substr(1).split("/")[0];
      if (!this.auth.user.roles.some((r) => r.role === val)) {
        return {
          btn: true,
          "btn-sm": true,
          "btn-outline-secondary": true,
          "mx-1": true,
        };
      }
      if (val === cab) {
        return { btn: true, "btn-sm": true, "btn-info": true, "mx-1": true };
      } else
        return {
          btn: true,
          "btn-sm": true,
          "btn-outline-info": true,
          "mx-1": true,
        };
    },
  },
};
</script>

<style scoped>
.user-roles > button {
  width: 120px;
}

.clickable {
  cursor: pointer;
}
</style>