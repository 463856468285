<template>
  <div style="font-size: 14px">
    <div>
      <div v-if="car.details && car.details.driverName">
        {{ car.details.driverName }}
        <div class="brdrtop" style="font-size: 12px">
          {{ car.details.carClass }} {{ car.details.carModel }} ({{
            car.details.carColor
          }}) [{{ car.details.carNumber }}] child seats:
          {{ car.details.childSeatsCount }}
        </div>
      </div>
      <div v-else>
        <span v-if="car.nickname">{{ car.nickname }}</span>
        <span v-else>{{ car.deviceId }}</span>
      </div>
    </div>

    <div class="brdrtop" style="font-size: 12px">
      <strong>Timestamp</strong>
      <div>GMT {{ fromOADate(car.appState.timestamp) }}</div>
      <div>Local {{ fromOADate(car.appState.timestampLocal) }}</div>
    </div>

    <div
      v-if="car.appState.lat"
      class="clickable brdrtop"
      style="font-size: 12px"
      @click="showOnMap(car.appState.lat, car.appState.lon)"
    >
      <div><strong>Location</strong></div>
      <span v-if="car.appState.address">{{ car.appState.address }}</span>
      <span v-else
        >{{ car.appState.lat.toFixed(6) }}
        {{ car.appState.lon.toFixed(6) }}</span
      >
    </div>

    <div v-if="activeVP" class="brdrtop" style="font-size: 12px">
      <strong>Voyage plan</strong>
      <div>
        <div>
          {{ activeVP.name ? activeVP.name : activeVP.filename }} ({{
            fromOADate(activeVP.timestamp)
          }})
        </div>
        <!-- <div>{{ activeVP.status }}</div> -->
        <div><strong>Current step:</strong> {{ activeVP.activeItem.name }} / {{ activeCmd.action }}</div>
        <div>[{{ activeCmd.state }}] {{ activeCmd.time_state }} {{ mmoment(activeCmd.estimatedTime) }}</div>
      </div>
      <div><strong>ETA:</strong> {{ mmoment(activeVP.estimatedEndTime) }}</div>
      <div>
        <span v-if="activeVP.endAddress">{{ activeVP.endAddress }}</span>
        <span v-else
          >{{ activeVP.endLat.toFixed(6) }}
          {{ activeVP.endLon.toFixed(6) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import map from "@/map2.js";

export default {
  props: {
    car: {},
  },
  computed: {
    activeVP() {
      if (
        this.car.appState.voyageTasks &&
        this.car.appState.voyageTasks.length > 0
      ) {
        var avp = this.car.appState.voyageTasks.find(
          (o) => o.status === "active"
        );
        if (avp) return avp;
      }
      return null;
    },
    activeCmd() {
      if (this.activeVP) {
        var activeCmd = this.activeVP.activeItem.commands.find(
          (o) => o.state === "started"
        );
        return activeCmd;
      }
      return null;
    },
  },
  methods: {
    mmoment(strDateTime) {
      return moment(strDateTime).toDate().toLocaleString("ru");
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    showOnMap(lat, lon) {
      map.singleMarker(lat, lon);
    },
  },
};
</script>

<style scoped>
strong {
  color: #636363;
}
.brdrtop {
  border-top: solid 1px #ced4da;
}
.clickable {
  cursor: pointer;
  color: rgb(37, 37, 121);
}
</style>