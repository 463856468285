<template>
  <div
    id="vp-top-menu"
    class="d-inline-flex justify-content-between"
    style="width: 100%"
  >
    <select v-model="currentVP" class="form-control mb-3" style="width: 300px" @change="setCurrentVP">
      <option
        v-for="vp in voyagePlans"
        :key="vp.filename"
        v-bind:value="vp.filename"
      >
        {{ vp.name ? vp.name : vp.filename }} ({{ mdate(vp.issueDate) }})
      </option>
    </select>
    <div>
    <router-link class="btn btn-sm btn-primary"
      style="width: 200px"
      :to="{ name: 'teleapi', params: { devid: deviceId } }"
      >back to TeleAPI</router-link
    ></div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    voyagePlans: null,
    currentVP: null,
    deviceId: null,
  },
  methods: {
    setCurrentVP() {
      this.$emit("setCurrentVP", this.currentVP);
    },
    mdate(datestr){
      return moment(datestr).format("YYYY-MM-DD HH:mm")
    },
  },
};
</script>