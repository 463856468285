<template>
  <div>
    <div v-if="geoContent">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <loc-link :to="{name: 'catalog'}" class="nav-link" :aclass="'active'">{{ $t('publisher.gc.catalog') }}</loc-link>
        </li>
        <!-- <li class="nav-item">
          <loc-link :to="{name: 'products'}" class="nav-link" :aclass="'active'">{{ $t('publisher.gc.products') }}</loc-link>
        </li> -->
        <li class="nav-item">
          <loc-link :to="{name: 'licenses'}" class="nav-link" :aclass="'active'">{{ $t('publisher.gc.licenses') }}</loc-link>
        </li>
        <!-- <li class="nav-item">
          <loc-link
            :to="{name: 'my_distributors'}"
            class="nav-link"
            :aclass="'active'"
          >{{ $t('publisher.gc.distributors') }}</loc-link>
        </li> -->
      </ul>
    </div>
    <router-view name="geoContentView"></router-view>
  </div>
</template>

<script>


export default {
  name: "GeoContent",

  props: ["gcid"],
  created() {
    this.$store.dispatch("geoContent/getGeoContent", this.gcid);
  },
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    }
  }
};
</script>