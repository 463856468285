<template>
  <div>
    <h6>Тарифы</h6>
    <table class="table table-sm">
      <tr class="tblhead">
        <td>класс</td>
        <td style="width: 200px">название</td>
        <td style="width: 100px">включено минут</td>
        <td style="width: 100px">включено км</td>
        <td style="width: 100px">мин.стоимость</td>
        <td style="width: 100px">подача</td>
        <td style="width: 100px">подача руб/км</td>
        <td style="width: 100px">поездка руб/км</td>
        <td style="width: 100px">поездка руб/мин</td>
        <td></td>
      </tr>
      <tr v-for="rate in rates" :key="rate.id">
        <td>{{ rate.rateName }}</td>
        <td>{{ rate.name }}</td>
        <td>{{ rate.includedPacketMinutes }}</td>
        <td>{{ rate.includedPacketKm }}</td>
        <td>{{ rate.overallMinPrice }}</td>
        <td>{{ rate.pickupPrice }}</td>
        <td>{{ rate.pickupKmRate }}</td>
        <td>{{ rate.passengerKmRate }}</td>
        <td>{{ rate.passengerMinutesRate }}</td>
        <td>
          <button
            class="btn btn-outline-danger btn-sm"
            @click="deleteRate(rate.id)"
          >
            Удалить
          </button>
        </td>
      </tr>
      <tr v-if="missedClasses.length > 0">
        <td>
          <select
            v-model="newRate.rateName"
            class="form-control form-control-sm"
          >
            <option value="" disabled selected>класс авто</option>
            <option
              v-for="cClass in missedClasses"
              :key="cClass"
              :value="cClass"
            >
              {{ cClass }}
            </option>
          </select>
        </td>
        <td>
          <input
            type="text"
            v-model="newRate.name"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.includedPacketMinutes"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.includedPacketKm"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.overallMinPrice"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.pickupPrice"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.pickupKmRate"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.passengerKmRate"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="number"
            v-model.number="newRate.passengerMinutesRate"
            class="form-control form-control-sm"
          />
        </td>
        <td>
          <button class="btn btn-outline-primary btn-sm" @click="saveRate()">
            Сохранить
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      rates: [],
      carClasses: ["Econom", "Comfort", "Business", "Premium"],
      newRate: { ...this.newRateTemplate },
      newRateTemplate: {
        id: 0,
        rateName: "",
        name: "",
        includedPacketMinutes: 0,
        includedPacketKm: 0,
        overallMinPrice: 0,
        pickupPrice: 0,
        pickupKmRate: 0,
        passengerKmRate: 0,
        passengerMinutesRate: 0,
      },
    };
  },
  mounted() {
    this.loadRates();
  },
  computed: {
    missedClasses() {
      var existingClasses = this.rates.map((o) => o.rateName);
      return this.carClasses.filter((el) => !existingClasses.includes(el));
    },
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    loadRates() {
      myAxios
        .get("http://pkapi.bgeo.fi/taxi_dev/tariff?userId=" + this.auth.user.id)
        .then((resp) => {
          this.rates = resp.data;
        });
    },
    saveRate() {
      this.newRate.userId = this.auth.user.id;
      myAxios
        .post("http://pkapi.bgeo.fi/taxi_dev/tariff/", this.newRate, {
          headers: { "Content-type": "application/json" },
        })
        .then(() => {
          this.loadRates();
          this.newRate = { ...this.newRateTemplateF };
        });
    },
    deleteRate(id) {
      myAxios.delete("http://pkapi.bgeo.fi/taxi_dev/tariff/" + id).then(() => {
        this.loadRates();
      });
    },
    isComplete() {
      return this.rateName & this.name & (this.includedPacketMinutes > 0);
    },
  },
};
</script>

<style scoped>
.tblhead td {
  font-size: 12px;
}
</style>