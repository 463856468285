<template>
  <div>
    <h5>Get Trips</h5>
    <div class="form-group my-4">
      <button type="button" class="btn btn-primary btn-sm" @click="getExchanges">
        Refresh
      </button>
    </div>
    <hr />
    <div>
      <h6>Trips</h6>
      <table class="exchlog table table-sm" v-if="exchanges">
        <tr>
          <td>Date</td>
          <td></td>
        </tr>
        <tr v-for="itm in exchanges.list" :key="itm.timestamp">
          <td>{{ new Date(itm.timestampDt).toLocaleString('ru') }}</td>
          <td><span class="filelink" @click="getMessageContent(itm.filename)">{{ itm.filename }}</span></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    deviceid: null,
  },
  data() {
    return {
      exchanges: null,
      file: null,
    };
  },
  created() {
    this.getExchanges();
  },
  methods: {
    processJson(jsonstr) {
      if (jsonstr === "no data") return null;
      return JSON.parse(jsonstr);
    },
    getExchanges() {
      myAxios
        .get("/api/teleapi/GetMessages", {
          params: { deviceId: this.deviceid, action: "TripList" },
        })
        .then((resp) => {
          this.exchanges = resp.data;
        })
    },
    sendRequest() {
      if (this.deviceid) {
        myAxios
          .get("/api/teleapi/CarInfoRequest", {
            params: { deviceId: this.deviceid },
          })
          .then(() => {
            this.getExchanges();
          });
      }
    },
    fromOADate(oadate) {
      var date = new Date((oadate - 25569) * 86400000);
      var tz = date.getTimezoneOffset();
      return new Date(
        (oadate - 25569 + tz / (60 * 24)) * 86400000
      ).toLocaleString("ru");
    },
    getMessageContent(filename) {
      myAxios
        .get("/api/teleapi/GetMessageContent", {
          params: { deviceId: this.deviceid, filename, action: "Trip" },
        })
        .then((response) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(response.data))
          );
          element.setAttribute("download", filename);
          element.style.display = "none";
          element.click();
          URL.revokeObjectURL(element.href);
        })
    },
  },
};
</script>

<style scoped>
.mapdiv {
  height: 500px;
}

.filelink {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>