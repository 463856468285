<template>
  <div>
    <add-s102 class="mt-2 mb-4"></add-s102>
    <div v-if="status && status.loaded">
      <p>
        {{ $tc("publisher.gc.cat.enc.list.total", s102Collection.length) }}
      </p>
      <table class="table table-sm" v-if="s102Collection.length > 0">
        <thead>
          <tr>
            <th>{{ $t("publisher.gc.cat.enc.list.chartName") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.ver") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.lastUpd") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.scale") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.band") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.descr") }}</th>
          </tr>
        </thead>
        <tr v-for="chart in s102Collection" :key="chart.id">
          <td>{{chart.name}}</td>
          <td>{{chart.edition}}.{{chart.update}}</td>
          <td><my-date :date="chart.releaseDate"></my-date></td>
          <td>{{chart.scale}}</td>
          <td>{{chart.band}}</td>
          <td>{{chart.description}}</td>
          <!-- <td><button class="btn btn-sm btn-outline-secondary">downgrade</button><button class="btn btn-sm btn-outline-secondary">remove</button></td> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import AddS102 from "@/components/geocontent/AddS102";

export default {
  components: { AddS102 },
  created() {
    this.$store.dispatch("s102/getS102Collection");
  },
  computed: {
    status() {
      if (this.$store.state.s102) return this.$store.state.s102.status;
      return false;
    },
    s102Collection() {
      return this.$store.state.s102.s102Collection.charts;
    },
  },
};
</script>

<style scoped>
th {
  color: grey;
}
</style>